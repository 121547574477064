import React from 'react'
import { Outlet } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import useGlobal from '../Core/global'
import {
  PiUserCircleLight,
  PiShoppingBagLight,
  PiSignOutLight
} from 'react-icons/pi'
const MyAccount = () => {
  const logout = useGlobal(state => state.logout)
  return (
    <div className='  h-auto w-full'>
      <div className='flex flex-col md:flex-row h-auto  py-[20px] md:py-[30px] lg:py-[40px]'>
        <div className=' flex flex-col items-center justify-between w-[250px]  rounded-lg'>
          <ul className=' my_account pl-[20px] text-secondary  flex flex-col items-center w-full gap-3'>
            <li className='w-full flex '>
              <NavLink
                to={'/my-account'}
                end
                className='py-2 px-5 border-transparent w-full flex items-center gap-5'
              >
                <PiUserCircleLight size={25} /> Account Detail
              </NavLink>
            </li>
            <li className='flex w-full'>
              <NavLink
                to={'/my-account/orders'}
                className='py-2 px-5 border-transparent w-full flex items-center gap-5'
              >
                <PiShoppingBagLight size={25} /> Orders
              </NavLink>
            </li>
            <NavLink
              onClick={() => logout()}
              className='py-2 px-5 border-transparent w-full flex items-center gap-5'
            >
              <PiSignOutLight size={25} /> Log out
            </NavLink>
          </ul>
        </div>

        <div className='w-full'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default MyAccount
