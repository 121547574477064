import React, { useState } from 'react'
import { Checkbox, Typography } from '@material-tailwind/react'
const Filter = ({ action, text }) => {
  return (
    <div>
      <Checkbox
        type='checkbox'
        value={text}
        onChange={e => {
          action(e)
        }}
        label={
          <Typography
            color='blue-gray'
            className=' text-secondary font-serif flex font-medium'
          >
            {text}
          </Typography>
        }
        ripple={false}
        className='border-secondary rounded-none'
      />
    </div>
  )
}

export default Filter
