import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { data, images } from '../Constants'
import Product from '../Components/Product'
import { motion } from 'framer-motion'
import ProductsSlider from '../Components/ProductsSlider'
const BrandDetail = () => {
  const { brand_id } = useParams()
  const [brand, setBrand] = useState()
  const [brandProducts, setBrandProducts] = useState()

  const handleSearchBrand = () => {
    let results = data.Brands.filter(data => `${data.id}` === brand_id)
    setBrand(results[0])
    let brand_products = data.Store.filter(
      data => `${data.brand_name}` === results[0].brand_name
    )
    setBrandProducts(brand_products)
  }

  useEffect(() => {
    handleSearchBrand()
  }, [brand_id])

  useEffect(() => {
    document.title = `${brand?.brand_name} | Abol Coffee`
  })

  console.log(brand)
  return (
    <div className='h-auto w-full'>
      <div className='m-auto h-auto max-w-[500px] p-[20px] md:p-[30px]  lg:p-[50px] '>
        <img
          src={brand?.image}
          alt=''
          className='overflow-hidden mix-blend-darken'
        />
      </div>
      <div className='m-auto h-auto max-w-[1240px] p-[20px] md:p-[30px]  lg:p-[50px flex flex-col items-center gap-4 justify-center'>
        <motion.h1
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.2 }}
          className='text-[30px] '
        >
          {brand?.brand_name}
        </motion.h1>
        <motion.p
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.4 }}
          className='text-center'
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Exercitationem similique numquam eum enim culpa mollitia et libero
          consequuntur quo? Vel perspiciatis ea officia amet aut, quae nihil
          Exercitationem similique numquam eum enim culpa mollitia et libero
          consequuntur quo? Vel perspiciatis ea officia amet aut, quae nihil
          Exercitationem similique numquam eum enim culpa mollitia et libero
          consequuntur quo? Vel perspiciatis ea officia amet aut, quae nihil
          obcaecati natus? Accusamus.
        </motion.p>
        <div>
          <motion.img
            initial={{ y: 150, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.4 }}
            src={images.toye_coffee_2}
            alt=''
          />
        </div>
        <h2>Product Origin</h2>
        <motion.p
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.4 }}
          className='text-center'
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Exercitationem similique numquam eum enim culpa mollitia et libero
          consequuntur quo? Vel perspiciatis ea officia amet aut, quae nihil
          Exercitationem similique numquam eum enim culpa mollitia et libero
          consequuntur quo? Vel perspiciatis ea officia amet aut, quae nihil
          Exercitationem similique numquam eum enim culpa mollitia et libero
          consequuntur quo? Vel perspiciatis ea officia amet aut, quae nihil
          obcaecati natus? Accusamus.
        </motion.p>
        <motion.h2
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.2 }}
          className='text-[30px] '
        >
          History
        </motion.h2>
        <motion.p
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.4 }}
          className='text-center'
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Exercitationem similique numquam eum enim culpa mollitia et libero
          consequuntur quo? Vel perspiciatis ea officia amet aut, quae nihil
          Exercitationem similique numquam eum enim culpa mollitia et libero
          consequuntur quo? Vel perspiciatis ea officia amet aut, quae nihil
          Exercitationem similique numquam eum enim culpa mollitia et libero
          consequuntur quo? Vel perspiciatis ea officia amet aut, quae nihil
          obcaecati natus? Accusamus.
        </motion.p>
        <motion.h2
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.2 }}
          className='text-[30px] text-center md:text-start '
        >
          Products of {brand?.brand_name}
        </motion.h2>
        <div className='w-full flex mb-5'>
          <ProductsSlider products={brandProducts} />
        </div>
        {/* <div className='grid grid-cols-2 lg:grid-cols-3 gap-5 mt-5 '>
          {brandProducts?.map((product, id) => (
            <Product key={id} product={product} />
          ))}
        </div> */}
      </div>
    </div>
  )
}

export default BrandDetail
