import React from 'react'
import { Link } from 'react-router-dom'
import useGlobal from '../Core/global'
import { motion } from 'framer-motion'
const Product = ({ product }) => {
  const {
    id,
    product_name,
    image,
    price_usd,
    price_birr,
    roast_level,
    available_stock
  } = product
  const country = useGlobal(state => state.country)
  return (
    <motion.div
      initial={{ y: 150, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 1 }}
    >
      <Link to={`/shop/${id}`}>
        <div
          className={`group border-dark/10 shadow-md min-h-[400px] h-full w-full overflow-hidden transition-all duration-300 ease-in hover:shadow-2xl rounded-sm  flex bg-secondary/5 hover:bg-secondary/10 flex-col items-start gap-5 border-[1px] border-secondary/5 justify-start `}
        >
          <div className='w-full h-[250px] transform transition-transform duration-500 ease-in-out group-hover:scale-110 '>
            <img src={image} className='object-cover ' />
          </div>
          <div className={`p-3 flex flex-col gap-2 w-full`}>
            <h2
              to={`/products/${id}`}
              className='sm:text-[18px] font-semibold text-[22px] group-hover:text-secondary'
            >
              {product_name}
            </h2>

            <div className='flex justify-between items-center w-full'>
              <p className='sm:px-3 sm:text-[15px] w-max py-1 px-4 bg-secondary/10 rounded-2xl'>
                {roast_level}
              </p>
              <p className=' text-[20px] sm:text-[25px]  font-semibold py-1 px-3  '>
                {country === 'ET' ? price_birr + ' ETB' : '$' + price_usd}
              </p>
            </div>
            {available_stock <= 10 && (
              <div className='bg-red-400 px-3 w-max rounded-md '>
                <p className='text-primary'>Stock: #{available_stock}</p>
              </div>
            )}
          </div>
        </div>
      </Link>
    </motion.div>
  )
}

export default Product
