import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AddItemtoStore from '../../Components/Admin/AddItemtoStore'
import UpdateStoreItemModel from '../../Components/Admin/UpdateStoreItemModel'
import { BiDotsVertical, BiEdit, BiTrash, BiDollar } from 'react-icons/bi'
import { data } from '../../Constants'
import useGlobal from '../../Core/global'
import utils from '../../Core/utils'
import Pagination from '../../Components/Pagination'
const Store = () => {
  const { openModal } = useGlobal()
  const handleOpenModal = itemId => {
    openModal(itemId)
  }

  const [showModal, setShowModal] = useState(false)

  const [filters, setFilters] = useState({
    product_name: '',
    brand_name: '',
    origin: ''
  })

  const handleChange = e => {
    const { name, value } = e.target
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }))
  }

  const filteredData = data.Store.filter(item => {
    return (
      (filters.product_name === '' ||
        item.product_name
          .toLowerCase()
          .includes(filters.product_name.toLowerCase())) &&
      (filters.brand_name === '' || item.brand_name === filters.brand_name) &&
      (filters.origin === '' || item.origin === filters.origin)
    )
  })

  const columns = [
    'NO',
    'Product Name',
    'Brand Name',
    'Coffee Type',
    'Roast Level',
    'Processed Type',
    'Package Size',
    'availibe Stock',
    'Price'
  ]

  // Pagination
  const [currentPage, setCurrentPage] = useState(0)
  const productsPerPage = 10 // This can be changed to set the items to display per  page

  const pageCount = Math.ceil(filteredData.length / productsPerPage)

  const handlePageChange = selectedPage => {
    setCurrentPage(selectedPage)
  }

  const displayProducts = filteredData.slice(
    currentPage * productsPerPage,
    (currentPage + 1) * productsPerPage
  )
  // Action options
  const actionsRef = useRef()

  const [showActions, setShowActions] = useState(false)
  useEffect(() => {
    const handleOutsideClick = event => {
      // Close modal if click is outside of modalRef
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setShowActions(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  return (
    <div className='h-full w-full flex flex-col px-2 md:px-4 py-5 bg-white overflow-x-scroll'>
      <div>
        <h1 className='text-[30px] font-bold'>Store #{data.Store.length}</h1>

        <div className='flex gap-4 items-center justify-between'>
          <div className='flex items-center gap-4'>
            <select
              name='brand_name'
              onChange={handleChange}
              value={filters.brand_name}
              className='p-2 e w-[150px]  hover:bg-secondary/90  px-4 py-2 outline-none  bg-secondary text-white border-r-4 border-secondary/80 cursor-pointer '
            >
              <option value=''>Coffee Brand</option>s
              {data.Brands.map((brand, key) => (
                <option key={key} value={brand.brand_name}>
                  {brand.brand_name}
                </option>
              ))}
            </select>
            <select
              name='origin'
              id='origin'
              onChange={handleChange}
              value={filters.origin}
              className='p-2 e w-[150px]  hover:bg-secondary/90  px-4 py-2 outline-none  bg-secondary text-white border-r-4 border-secondary/80 cursor-pointer '
            >
              <option value=''>Coffee Origin</option>
              <option value='Yirga chaffe'>Yirga cheffee Coffee</option>
              <option value='Harrar'>Harar Coffee</option>
              <option value='Jimma'>Jimma Coffee</option>
              <option value='TWollega'>Wollega Coffee</option>
              <option value='Guji'>Guji Coffee</option>
              <option value='Sidama'>Sidama Coffee</option>
              <option value='Bale'>Bale Coffee</option>
            </select>
            <input
              type='text'
              name='product_name'
              placeholder='Product name'
              className='w-[250px]'
              onChange={handleChange}
              value={filters.product_name}
            />
          </div>
          <div className='flex items-center gap-4'>
            <button
              onClick={() => setShowModal(!showModal)}
              type='button'
              className='p-2 e w-[150px]  hover:bg-secondary/90  px-4 py-3 bg-secondary text-white'
            >
              Add New Item
            </button>
            <button
              type='button'
              onClick={() => utils.exportToCSV(data.Store, 'Store')}
              className='p-2 e w-[150px] hover:bg-secondary/90  px-4 py-3 bg-secondary text-white'
            >
              Export CSV
            </button>
          </div>
        </div>

        <table className='w-full mt-3' id='store-table'>
          <thead className=''>
            <tr>
              <td>No</td>
              <td>Product Name</td>
              <td>Brand Name</td>
              <td>Roast Level</td>
              <td>Processed Types</td>
              <td>Package Sizes</td>
              <td>Availibe Stock</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {displayProducts.map((item, key) => (
              <tr key={item.id}>
                <td className='max-w-[20px]'>{key + 1}</td>
                <td>{item.product_name}</td>
                <td>{item.brand_name}</td>
                <td>{item.roast_level}</td>
                <td>
                  <p>
                    {item.processed_type.map((size, key) => (
                      <span> {size}</span>
                    ))}
                  </p>
                </td>
                <td>
                  <p>
                    {item.package_size.map((size, key) => (
                      <span> {size}</span>
                    ))}
                  </p>
                </td>
                <td>{item.available_stock}</td>
                <td className='relative'>
                  <div className='relative store-action-menu w-max'>
                    <BiDotsVertical size={20} className='-z-5  ' />

                    <ul className='absolute top-0 -right-[20px] z-[10] bg-white rounded-md shadow-md p-2 min-w-[180px] store-menus'>
                      <li className='flex text-dark px-2 py-1 rounded-lg hover:bg-dark/10 w-full'>
                        <Link
                          to={`pricing/${key}`}
                          state={{ item: item }}
                          className={' flex items-center w-full'}
                        >
                          <BiDollar />
                          pricing
                        </Link>
                      </li>
                      <li className='flex text-dark px-2 py-1 rounded-lg hover:bg-dark/10 w-full'>
                        <Link
                          onClick={() => handleOpenModal(item.id)}
                          className={' flex items-center w-full'}
                        >
                          <BiEdit />
                          Update Product
                        </Link>
                      </li>
                      <li className='flex text-dark px-2 py-1 rounded-lg hover:bg-red-600/30 w-full'>
                        <Link className={' flex items-center w-full'}>
                          <BiTrash /> Delete Product
                        </Link>
                      </li>
                    </ul>
                  </div>
                </td>
                <td>
                  <UpdateStoreItemModel item={item} onClose={() => {}} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
      </div>
      <AddItemtoStore showModal={showModal} setShowModal={setShowModal} />
    </div>
  )
}

export default Store
