import React, { useRef, useEffect, useState } from 'react'
import Flickity from 'flickity'
import { PiCaretLeft, PiCaretRight } from 'react-icons/pi'
import { data } from '../Constants'
import { motion, AnimatePresence } from 'framer-motion'
import { Button } from '@material-tailwind/react'
import { useNavigate } from 'react-router-dom'
import { images } from '../Constants'
const BrandsSlider = () => {
  const flickityRef = useRef(null)
  const flkty = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const navigate = useNavigate()

  const textVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 40 }
  }

  useEffect(() => {
    flkty.current = new Flickity(flickityRef.current, {
      initialIndex: 0,
      cellAlign: 'center',
      wrapAround: true,
      pageDots: false,
      imagesLoaded: true,
      prevNextButtons: false,
      selectedAttraction: 0.01,
      friction: 0.2
    })

    flkty.current.on('change', index => {
      setCurrentSlide(index)
    })

    return () => {
      flkty.current.destroy()
    }
  }, [])
  const handlePrev = () => {
    if (flkty.current) {
      flkty.current.previous()
    }
  }

  const handleNext = () => {
    if (flkty.current) {
      flkty.current.next()
    }
  }
  return (
    <motion.div className='brand_slider relative w-full py-10 md:p-0 h-[800px] md:h-[700px] '>
      <div className='absolute w-full  h-full z-[0] '>
        <img src={images.paper} alt='' className=' object-cover' />
      </div>
      <div className='flickity-slider' ref={flickityRef}>
        {data.Brands.map((brand, index) => (
          <div key={index} className='carousel-cell w-full md:w-[65%] h-full '>
            <div className='h-[300px] md:h-full mt-10 md:m-0 w-full  md:w-[500px] top-0 '>
              <img
                src={brand.product_image}
                className='object-contain transform duration-100 ease-in '
              />
            </div>
          </div>
        ))}
      </div>
      <AnimatePresence>
        <motion.div className='w-full absolute art bottom-[20px] p-[20px] md:p-0 md:top-1/2 md:right-[200px] transform md:-translate-y-1/2 md:w-[350px] flex flex-col gap-3 items-center md:items-start'>
          <motion.h1
            key={data.Brands[currentSlide].brand_name}
            initial='hidden'
            animate='visible'
            exit='exit'
            transition={{ duration: 1 }}
            variants={textVariants}
            className=' text-center md:text-start  w-full'
          >
            {data.Brands[currentSlide].brand_name}
          </motion.h1>
          <motion.p
            key={data.Brands[currentSlide].detail}
            initial='hidden'
            animate='visible'
            exit='exit'
            transition={{ duration: 1 }}
            variants={textVariants}
            className=' text-center md:text-start  w-full'
          >
            {data.Brands[currentSlide].detail}
          </motion.p>
          <motion.div
            key={data.Brands[currentSlide].id}
            initial='hidden'
            animate='visible'
            exit='exit'
            transition={{ duration: 1 }}
            variants={textVariants}
          >
            <Button
              onClick={() => navigate(`brands/${data.Brands[currentSlide].id}`)}
              className='p-3 bg-accent font-serif text-[17px] capitalize w-[200px] hover:bg-olivegreen rounded-none text-primary'
            >
              Learn More
            </Button>
          </motion.div>
        </motion.div>
      </AnimatePresence>
      <div className='absolute flex  right-1/2 transform  translate-x-1/2 -bottom-[50px] mf:-translate-x-0 md:bottom-0 md:right-[200px] gap-3 md:gap-5'>
        <button
          onClick={handlePrev}
          className=' border-[1px] border-white top-1/2 transform right-0 flex items-center justify-center text-center hover:bg-olivegreen/20 rounded-full  -translate-y-1/2 p-[10px] bg-accent/20 w-p[50px] h-[50px]  md:w-[100px] md:h-[100px]'
        >
          <PiCaretLeft className='text-[30px]  md:text-[60px]' color='white' />
        </button>
        <button
          onClick={handleNext}
          className=' border-[1px] border-white top-1/2 transform right-0 flex items-center justify-center text-center hover:bg-olivegreen/20 rounded-full -translate-y-1/2 p-[10px] bg-accent/20 w-p[50px] h-[50px] md:w-[100px] md:h-[100px]'
        >
          <PiCaretRight className='text-[30px]  md:text-[60px]' color='white' />
        </button>
      </div>
    </motion.div>
  )
}

export default BrandsSlider
