import React, { useState, useEffect } from 'react'
import useGlobal from '../Core/global'
import InputField from '../Components/InputField'
import { Button } from '@material-tailwind/react'
const PlaceOrder = () => {
  const cartItems = useGlobal(state => state.cartItems)
  const [showDiscount, setShowDiscount] = useState(false)
  const [orderInfo, setOrderInfo] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    city: '',
    address: '',
    delivery_instruction: '',
    discount_code: ''
  })
  const [phoneError, setPhoneError] = useState('')
  const [emailError, setEmaiError] = useState('')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [addressError, setAddressError] = useState('')
  const [cityError, setCityError] = useState('')

  const handleChange = e => {
    setOrderInfo({
      ...orderInfo,
      [e.target.name]: e.target.value
    })
  }
  const handleOrder = e => {
    e.preventDefault()
    if (!validateOrder()) {
      return
    } else {
      console.log('handle order')
    }
  }

  const validateOrder = () => {
    const failFirstName =
      !orderInfo.first_name || orderInfo.first_name.length === 0
    if (failFirstName) {
      setFirstNameError('First Name is Required!')
    } else {
      setFirstNameError('')
    }

    const failLastName =
      !orderInfo.last_name || orderInfo.last_name.length === 0
    if (failLastName) {
      setLastNameError('Last name is required!')
    } else {
      setLastNameError('')
    }
    const failEmail = !orderInfo.email || orderInfo.email.length === 0
    if (failEmail) {
      setEmaiError('Email is required!')
    } else {
      setEmaiError('')
    }

    const failCity = !orderInfo.city
    if (failCity) {
      setCityError('City is required!')
    } else {
      setCityError('')
    }

    const failPhoneNumber = !orderInfo.phone_number
    if (failPhoneNumber) {
      setPhoneError('Phone is required!')
    } else {
      setPhoneError('')
    }
    const failAddress = !orderInfo.address
    if (failAddress) {
      setAddressError('Address is required!')
    } else {
      setAddressError('')
    }

    if (failFirstName || failLastName || failEmail || failAddress) {
      return false
    }
  }

  const [totalItems, setTotalItems] = useState('')
  const country = useGlobal(state => state.country)
  const [totalPrice, setTotalPrice] = useState('')

  useEffect(() => {
    let totalItems = 0
    cartItems.forEach(item => (totalItems += item.quantity))
    setTotalItems(totalItems)

    let totalPrice = 0
    cartItems.forEach(
      item =>
        (totalPrice +=
          country === 'ET'
            ? item.price_birr * item.quantity
            : item.price_usd * item.quantity)
    )
    setTotalPrice(totalPrice)
  }, [cartItems])
  useEffect(() => {
    document.title = 'Place Order | Abol Coffee'
  })
  return (
    <div className=' min-h-screen w-full'>
      <div className='m-auto h-auto max-w-[1440px] p-[20px] md:p-[30px] lg:p-[40px] flex-col items-start justify-between gap-10'>
        <div className='space-y-5 mb-5'>
          <h1 className=' capitalize mb-[10px]  '>Order Details</h1>
          <p>Fill the folloiwing form to place your order.</p>
        </div>
        <div className='w-full flex flex-col items-start gap-10 md:flex-row'>
          <form className='flex flex-col gap-5 w-full md:w-[60%]'>
            <div className='w-full flex flex-col gap-[5px]'>
              <InputField
                type='text'
                name='first_name'
                error={firstNameError && firstNameError}
                placeholder='Enter your first name'
                onChange={e => handleChange(e)}
                required={true}
                label='First Name'
              />
            </div>
            <div className='w-full flex flex-col gap-[5px]'>
              <InputField
                type='text'
                name='last_name'
                error={lastNameError && lastNameError}
                placeholder='Enter your last name'
                onChange={e => handleChange(e)}
                label='Last Name'
                required={true}
              />
            </div>

            <div className='w-full flex flex-col gap-[5px]'>
              <InputField
                type='email'
                name='email'
                label='Email'
                placeholder='Enter your Email!'
                onChange={e => handleChange(e)}
                error={emailError && emailError}
                required={true}
              />
            </div>
            <div className='w-full flex flex-col gap-[5px]'>
              <InputField
                type='number'
                name='phone_number'
                error={phoneError && phoneError}
                placeholder='Enter your phone number'
                onChange={e => handleChange(e)}
                required={true}
                label='Phone Number'
              />
            </div>

            <div className='w-full flex flex-col gap-[5px]'>
              <InputField
                type='text'
                name='city'
                error={cityError && cityError}
                placeholder='Enter your city'
                onChange={e => handleChange(e)}
                label='City'
                required={true}
              />
            </div>
            <div className='w-full flex flex-col gap-[5px]'>
              <InputField
                type='text'
                name='address'
                error={addressError && addressError}
                required={true}
                placeholder='Enter your address'
                onChange={e => handleChange(e)}
                label='Address'
              />
            </div>
            <div className='w-full flex flex-col gap-[5px] input-container'>
              <p>Delivery Instruction (optionsl)</p>
              <textarea
                type='text'
                name='delivery_instruction'
                placeholder='Delivery Instrution'
                onChange={e => handleChange(e)}
                rows={5}
              ></textarea>
            </div>
            <div
              className={` w-max text-secondary  underline cursor-pointer`}
              onClick={() => setShowDiscount(!showDiscount)}
            >
              <p> I have discount code</p>
            </div>
            <div
              className={` ${
                showDiscount ? 'flex' : 'hidden'
              } w-full  flex-col gap-[5px] `}
            >
              <InputField
                type='number'
                name='discount_code'
                placeholder='Discount Code'
                onChange={e => handleChange(e)}
                label='Discount Code'
              />
            </div>
            <Button
              type='submit'
              onClick={e => handleOrder(e)}
              className='p-3 bg-secondary text-primary border-[2px] shadow-none  font-serif text-[17px] capitalize w-[200px] hover:border-secondary hover:bg-transparent rounded-none hover:text-secondary'
              >
              Place Order
            </Button>
          </form>
          <div className='w-full md:w-[40%]  shadow-md p-2'>
            <h1 className='capitalize mb-[10px] text-[35px] '>Your Order</h1>
            <ul className='flex flex-col  gap-2'>
              {cartItems.map((item, key) => (
                <li
                  className='flex justify-between items-center pb-3 border-b-2 border-dark/15'
                  key={key}
                >
                  <div className='w-[100px] h-[100px]'>
                    <img src={item.image} alt='' className='object-cover' />
                  </div>
                  <p className=' text-[20px] w-1/2'>
                    {item.quantity}x {item.product_name}
                  </p>
                  <p className='text-[20px]  font-semibold'>
                    {country === 'ET'
                      ? item.price_birr * item.quantity + ' ETB'
                      : '$' + item.price_usd * item.quantity}{' '}
                  </p>
                </li>
              ))}

              <li className='flex justify-between items-center '>
                <h2 className='font-bold'>Total</h2>
                <h2 className='text-[35px] font-bold'>
                  {' '}
                  {country === 'ET'
                    ? totalPrice + ' ETB'
                    : '$' + totalPrice}{' '}
                </h2>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlaceOrder
