import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { images } from '../Constants'
import InputField from '../Components/InputField'
import { Button } from '@material-tailwind/react'

const About = () => {
  const [contact, setContact] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  })

  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [subjectError, setSubjectError] = useState('')
  const [messageError, setMessageError] = useState('')

  const handleChange = e => {
    setContact({
      ...contact,
      [e.target.name]: e.target.value
    })
  }

  const handleContact = e => {
    e.preventDefault()
    if (validateContact() === false) {
      return
    } else {
      console.log('Send Contact')
    }
  }

  const validateContact = () => {
    let failName = !contact.name || contact.name.length === 0

    if (failName) {
      setNameError('Name is required')
    } else {
      setNameError('')
    }

    let failEmail = !contact.email || contact.email.length === 0

    if (failEmail) {
      setEmailError('Email is required')
    } else {
      setEmailError('')
    }

    let failSubject = !contact.subject || contact.subject.length === 0

    if (failSubject) {
      setSubjectError('subject is required')
    } else {
      setSubjectError('')
    }

    let failMessage = !contact.message || contact.message.length === 0

    if (failMessage) {
      setMessageError('Message is required')
    } else {
      setMessageError('')
    }

    if (failName || failEmail || failSubject || failMessage) {
      return false
    }
  }

  return (
    <div className='h-auto w-full'>
      <div className='w-full h-[50vh] bg-black relative'>
        <img
          src={images.aboutus_banner}
          alt='anout us page bannder'
          className='opacity-70'
        />
        <h1 className='absolute text-primary top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          About Us
        </h1>
      </div>

      {/* who we are */}
      <div className='m-auto h-auto max-w-[1440px] items-center justify-between  py-[50px] px-[20px] md:px-[30px] lg:px-[50px] gap-[20px] flex flex-col md:flex-row '>
        <div className='flex flex-col gap-5 items-start justify-start p-0 md:py-[50px] w-full md:w-1/2'>
          <motion.h1
            initial={{ y: 150, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.2 }}
            className='text-center md:text-start w-full mb-[20px] text-secondary line-after-brown'
          >
            Who We Are!
          </motion.h1>
          <motion.p
            initial={{ y: 150, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.3 }}
            className='text-center md:text-start'
          >
            Abol Coffee is your premier online destination for authentic
            Ethiopian coffee. We curate and offer a diverse selection of
            packaged coffees from various local Ethiopian brands, bringing the
            finest beans from Ethiopia's renowned growing regions directly to
            your doorstep.
          </motion.p>
          <motion.p
            initial={{ y: 150, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.4 }}
            className='text-center md:text-start'
          >
            Discover the unique flavors and stories behind each brand as we
            connect you with Ethiopia's rich coffee culture.
          </motion.p>
        </div>
        <div className='w-full hidden md:flex md:w-[35%] h-[500px]'>
          <motion.img
            initial={{ y: -150, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.3 }}
            src={images.coffee_harvest}
            alt=''
          />
        </div>
      </div>

      {/* Our Service */}
      <div className='w-full h-auto bg-secondary/5'>
        <div className='m-auto h-auto max-w-[1440px] items-center justify-between  py-[50px] px-[20px] md:px-[30px] lg:px-[50px] gap-[20px] flex flex-col md:flex-row '>
          <div className='w-full hidden md:flex md:w-[35%] h-[500px]'>
            <motion.img
              initial={{ y: -150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.3 }}
              src={images.cup}
              alt=''
            />
          </div>
          <div className='flex flex-col gap-5 items-start justify-start p-0 md:py-[50px] w-full md:w-1/2'>
            <motion.h1
              initial={{ y: 150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.2 }}
              className='text-center md:text-start w-full mb-[20px] text-secondary line-after-brown'
            >
              Our Services!
            </motion.h1>
            <motion.p
              initial={{ y: 150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.3 }}
              className='text-center md:text-start'
            >
              Abol Coffee is your premier online destination for authentic
              Ethiopian coffee. We curate and offer a diverse selection of
              packaged coffees from various local Ethiopian brands, bringing the
              finest beans from Ethiopia's renowned growing regions directly to
              your doorstep.
            </motion.p>
            <motion.p
              initial={{ y: 150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.4 }}
              className='text-center md:text-start'
            >
              Discover the unique flavors and stories behind each brand as we
              connect you with Ethiopia's rich coffee culture.
            </motion.p>
          </div>
        </div>
      </div>

      {/*  How to reach us */}
      <div className='m-auto h-auto max-w-[1440px] items-center justify-between  py-[50px] px-[20px] md:px-[30px] lg:px-[50px] gap-[20px] flex flex-col md:flex-row '>
        <div className='flex flex-col gap-5 items-start justify-start p-0 md:py-[50px] w-full md:w-1/2'>
          <motion.h1
            initial={{ y: 150, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.2 }}
            className='text-center md:text-start w-full mb-[20px] text-secondary sm:line-center line-after-brown'
          >
            Contact Us
          </motion.h1>

          <motion.div
            initial={{ y: 150, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.4 }}
            className='w-full'
          >
            <form
              className='w-full flex flex-col items-start gap-4'
              onSubmit={handleContact}
            >
              <InputField
                onChange={handleChange}
                type='text'
                label='Full Name'
                required={true}
                name='full_name'
                placeholder='Enter your Name'
                error={nameError && nameError}
              />
              <InputField
                onChange={handleChange}
                type='email'
                label='Email'
                required={true}
                name='email'
                placeholder='Enter your Email'
                error={emailError && emailError}
              />
              <InputField
                onChange={handleChange}
                type='text'
                label='Subject'
                required={true}
                name='subject'
                placeholder='Subject'
                error={subjectError && subjectError}
              />

              <div className='input-container w-full'>
                <label>Message</label>
                <textarea
                  style={
                    messageError
                      ? {
                          borderColor: 'red'
                        }
                      : {}
                  }
                  onChange={handleChange}
                  name='message'
                  id='message'
                  placeholder='Message'
                  rows={5}
                  className='resize-none w-full'
                ></textarea>

                {messageError && <p className='text-red-500'>{messageError}</p>}
              </div>
              <Button
                type='submit'
                className='p-3 bg-secondary text-primary border-[2px] shadow-none  font-serif text-[17px] capitalize w-[200px] hover:border-secondary hover:bg-transparent rounded-none hover:text-secondary'
              >
                Send Message
              </Button>
            </form>
          </motion.div>
        </div>
        <div className='w-full hidden md:flex md:w-[35%] h-[500px]'>
          <motion.img
            initial={{ y: -150, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.3 }}
            src={images.coffee_mountain}
            alt=''
          />
        </div>
      </div>
    </div>
  )
}

export default About
