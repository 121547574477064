import spinner from '../assets/ss.svg'
import coffee_beans from '../assets/coffee_beans.jpg'
import coffee_beans_2 from '../assets/coffee_beans_3.jpg'
import coffee_barista from '../assets/coffee_barista.jpg'
import coffee_roast from '../assets/coffee_roast.jpg'
import coffee_cup from '../assets/coffee_cup.jpg'
import hadero_coffee_2 from '../assets/hadero_coffee_2.jpeg'
import coffee_tree from '../assets/coffee_tree.webp'
import delivery_motor_biker from '../assets/delivery_motor_biker.jpeg'
import cubico_coffee from '../assets/cubico_coffee.png'
import arada_coffee from '../assets/arada_coffee.jpg'
import hadero_coffee from '../assets/hadero_coffee.png'
import marley_coffee from '../assets/marley_coffee.png'
import grumpy_coffee from '../assets/grumpy_coffee.png'
import grey_coffee from '../assets/grey_coffee.webp'
import kaldis_coffee from '../assets/kaldis_coffee.webp'
import enabe_coffee from '../assets/enabe_cofffee.jpg'
import tomoca_coffee from '../assets/tomoca_coffee.png'
import adiss_ababa_coffee from '../assets/adiss_ababa_coffee.png'
import toye_coffee from '../assets/toye_coffee.webp'
import alem_coffee from '../assets/alem_coffee.jpg'
import wild_coffee from '../assets/wild_coffee.png'
import coffee_bag from '../assets/coffee_bag.png'
import toye_coffee_2 from '../assets/toye_coffee_2.jpg'
import kaldis_banner from '../assets/kaldis_banner.webp'
//import coffee_farm from "../assets/coffee_farm"
//Brands image
import hadero_coffee_logo from '../assets/hadero_logo.png'
import kaldis_logo from '../assets/kaldis_logo.png'
import enabe_logo from '../assets/enabe_logo.png'
import tomoca_logo from '../assets/tomoca_logo.jpeg'
import marley_logo from '../assets/marley_logo.jpeg'
import toye_logo from '../assets/toye_logo.png'
import adiss_ababa_coffee_logo from '../assets/adiss_ababa_logo.png'
import cubico_logo from '../assets/cubico_logo.jpeg'
import alem_logo from '../assets/alem_logo.jpeg'
import wild_logo from '../assets/wild_logo.png'
import tomoca_banner from '../assets/tomoca_banner.jpg'
import coffee from '../assets/coffee.png'
import jbena_2 from '../assets/jbena_2.png'
import tomoca_product from '../assets/tomoca_product.png'
import hadero_product from '../assets/hadero_product.png'
import coffee_product from '../assets/coffee_product.png'
import delivery from '../assets/delivery.png'
import hot_coffee from '../assets/hot_coffee.png'
import coffee_baner from '../assets/coffee_banner.jpeg'
import paper from '../assets/paper.png'
import cup from '../assets/cup.jpeg'
import leaf_1 from '../assets/leaf_1.png'
import leaf_2 from '../assets/leaf_2.png'
import aboutus_banner from '../assets/aboutus_bannder.jpeg'
import coffee_harvest from '../assets/coffee_harverst.png'
import coffee_mountain from '../assets/coffee_mountain.png'
import kedemit_coffee_image from '../assets/kedemit_coffee_image.jpeg'
import wild_coffee_image from '../assets/wild_coffee_image.jpeg'

export default {
  aboutus_banner,
  wild_coffee_image,
  kedemit_coffee_image,
  coffee_mountain,
  coffee_harvest,
  paper,
  leaf_1,
  leaf_2,
  cup,
  coffee_baner,
  hot_coffee,
  delivery,
  coffee_product,
  tomoca_product,
  hadero_product,
  jbena_2,
  coffee,
  coffee_beans_2,
  coffee_bag,
  spinner,
  coffee_beans,
  coffee_barista,
  coffee_cup,
  coffee_roast,
  hadero_coffee_2,
  delivery_motor_biker,
  toye_coffee_2,
  coffee_tree,
  kaldis_banner,
  tomoca_banner,
  adiss_ababa_coffee,

  cubico_coffee,
  arada_coffee,
  hadero_coffee,
  marley_coffee,
  grumpy_coffee,
  grey_coffee,
  kaldis_coffee,
  enabe_coffee,
  toye_coffee,
  tomoca_coffee,
  alem_coffee,
  wild_coffee,

  hadero_coffee_logo,
  alem_logo,
  kaldis_logo,
  enabe_logo,
  tomoca_logo,
  marley_logo,
  toye_logo,
  adiss_ababa_coffee_logo,
  cubico_logo,
  wild_logo
}
