import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { LuView } from 'react-icons/lu'
import useGlobal from '../../Core/global'
import { RiDiscountPercentLine } from 'react-icons/ri'
import {
  PiShoppingBagLight,
  PiUser,
  PiStorefrontLight,
  PiSignOutLight,
  PiListBold
} from 'react-icons/pi'
const Menus = ({ showmenu, setShowMenu }) => {
  const logout = useGlobal(state => state.logout)
  const navigate = useNavigate()
  return (
    <div
      className={`${
        showmenu ? 'w-[200px]' : 'w-[55px]'
      } bg-primary flex flex-col justify-between  min-h-[70vh] h-full px-2 py-5 `}
    >
      <div className='flex flex-col w-full h-max'>
        <button>
          <PiListBold
            className=' p-1 hover:bg-white rounded-lg cursor-pointer f mb-3 ml-1 flex  items-center'
            size={30}
            onClick={() => {
              setShowMenu(!showmenu)
            }}
          />
        </button>

        <NavLink
          to={''}
          end
          style={({ isActive }) => {
            return isActive ? { backgroundColor: 'rgba(255, 255, 255, 1)' } : {}
          }}
          className='px-2 py-1 h-max w-full rounded-lg  focus:bg-white hover:bg-white text-main-black mb-3 flex gap-2 items-center'
        >
          <LuView size={22} />
          {showmenu ? 'Overview' : ''}
        </NavLink>

        <NavLink
          style={({ isActive }) => {
            return isActive ? { backgroundColor: 'rgba(255, 255, 255, 1)' } : {}
          }}
          to={'store'}
          className='px-2 py-1 h-max w-full rounded-lg focus:bg-white hover:bg-white text-main-black mb-3 flex gap-2 items-center'
        >
          <PiStorefrontLight size={22} />
          {showmenu ? 'Store' : ''}
        </NavLink>
        <NavLink
          to={'orders'}
          style={({ isActive }) => {
            return isActive ? { backgroundColor: 'rgba(255, 255, 255, 1)' } : {}
          }}
          className='px-2 py-1 h-max w-full rounded-lg focus:bg-white hover:bg-white text-main-black mb-3 flex gap-2 items-center'
        >
          <PiShoppingBagLight size={22} />
          {showmenu ? 'Orders' : ''}
        </NavLink>
        <NavLink
          style={({ isActive }) => {
            return isActive ? { backgroundColor: 'rgba(255, 255, 255, 1)' } : {}
          }}
          to={'discount'}
          className='px-2 py-1 h-max w-full rounded-lg focus:bg-white hover:bg-white text-main-black mb-3 flex gap-2 items-center'
        >
          <RiDiscountPercentLine size={22} />
          {showmenu ? 'Discount' : ''}
        </NavLink>
        <NavLink
          style={({ isActive }) => {
            return isActive ? { backgroundColor: 'rgba(255, 255, 255, 1)' } : {}
          }}
          to={'users'}
          className='px-2 py-1 h-max w-full rounded-lg focus:bg-white hover:bg-white text-main-black mb-3 flex gap-2 items-center'
        >
          <PiUser size={22} />
          {showmenu ? 'Users' : ''}
        </NavLink>
      </div>
      <div className=''>
        <NavLink
          onClick={() => {
            navigate('/')
            logout()
          }}
          className='px-2 py-1 h-max w-full rounded-lg focus:bg-white hover:bg-white text-main-black mb-3 flex gap-2 items-center'
        >
          <PiSignOutLight size={22} />
          {showmenu ? 'Log Out' : ''}
        </NavLink>
      </div>
    </div>
  )
}

export default Menus
