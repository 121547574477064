import { create } from 'zustand'

const useGlobal = create(set => ({
  //Toast Message
  toasts: [],
  addToast: (message, status) => {
    const id = Date.now()
    set(state => ({
      toasts: [...state.toasts, { id, message, status }]
    }))
    setTimeout(() => {
      set(state => ({
        toasts: state.toasts.filter(toast => toast.id !== id)
      }))
    }, 3000)
  },
  removeToast: id =>
    set(state => ({
      toasts: state.toasts.filter(toast => toast.id !== id)
    })),
  // Counrty
  country: localStorage.getItem('country')
    ? localStorage.getItem('country')
    : null,
  setCountry: country => {
    localStorage.setItem('country', country)
    set(state => ({
      country: country
    }))
  },
  currency: localStorage.getItem('currency')
    ? localStorage.getItem('currency')
    : 'ETB',
  setCurrency: currency => {
    localStorage.setItem('currency', currency)
    set(state => ({
      currency: currency
    }))
  },

  //Cart
  cartItems: JSON.parse(localStorage.getItem('cartItems')) || [],

  addItemToCart: newItem =>
    set(state => {
      const indexId = state.cartItems.findIndex(item => item.id === newItem.id)
      if (indexId >= 0) {
        state.cartItems[indexId].quantity += newItem.quantity
      } else {
        state.cartItems = [...state.cartItems, newItem]
      }
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
      return { cartItems: state.cartItems }
    }),

  removeItemFromCart: itemId =>
    set(state => {
      const updatedCart = state.cartItems.filter(item => item.id !== itemId)
      localStorage.setItem('cartItems', JSON.stringify(updatedCart))
      return { cartItems: updatedCart }
    }),
  updateCartItemQuantity: (itemId, newQuantity) =>
    set(state => {
      const updatedCart = state.cartItems.map(item => {
        if (item.id === itemId) {
          return { ...item, quantity: newQuantity }
        }
        return item
      })
      localStorage.setItem('cartItems', JSON.stringify(updatedCart))
      return { cartItems: updatedCart }
    }),

  // Authentication
  authenticated: !!localStorage.getItem('user'),
  user: localStorage.getItem('user') ? localStorage.getItem('user') : null,
  admin: localStorage.getItem('isAdmin')
    ? localStorage.getItem('isAdmin') === 'true'
      ? true
      : false
    : false,

  init: () => {
    const user = localStorage.getItem('user')
    if (user) {
      set(state => ({
        authenticated: true,
        user: user
      }))
    }
  },

  login: data => {
    localStorage.setItem('user', JSON.stringify(data))
    if (data.email === 'admin@gmail.com') {
      localStorage.setItem('isAdmin', true)
      set(state => ({
        authenticated: true,
        user: data,
        admin: true
      }))
    } else {
      localStorage.setItem('isAdmin', false)
      set(state => ({
        authenticated: true,
        user: data,
        admin: false
      }))
    }
  },

  logout: () => {
    localStorage.removeItem('user')
    localStorage.removeItem('isAdmin')

    set(state => ({
      authenticated: false,
      user: null,
      admin: false
    }))
  },

  //Modals
  modalOpen: {},
  openModal: itemId =>
    set(state => ({
      modalOpen: { ...state.modalOpen, [itemId]: true }
    })),
  closeModal: itemId =>
    set(state => ({
      modalOpen: { ...state.modalOpen, [itemId]: false }
    }))
}))

export default useGlobal
