import React, { useState, useEffect } from 'react'
import Product from '../Components/Product'
import { data } from '../Constants'
import { useParams } from 'react-router-dom'
import Pagination from '../Components/Pagination'

const SearchResults = () => {
  const { searched } = useParams()

  const [searchResults, setSearchResults] = useState([])

  const handleSearch = () => {
    let results = data.Store.filter(data =>
      data.product_name.toLowerCase().includes(searched.toLowerCase())
    )
    setSearchResults(results)
  }

  useEffect(() => {
    document.title = `${searched} | Abol Coffee`
    handleSearch()
  }, [searched])

  useEffect(() => {
    document.title = `${searched} | Abol Coffee`
  })

  // Search Results page Pagination
  const [currentPage, setCurrentPage] = useState(0)
  const productsPerPage = 6 // This can be changed to set the items to display per  page

  const pageCount = Math.ceil(searchResults.length / productsPerPage)

  const handlePageChange = selectedPage => {
    setCurrentPage(selectedPage)
  }

  const searchResultsData = searchResults.slice(
    currentPage * productsPerPage,
    (currentPage + 1) * productsPerPage
  )
  return (
    <div className='h-auto w-full'>
      <div className='  min-h-[80vh] w-full'>
        <div className='m-auto h-auto max-w-[1440px] p-[20px] md:p-[30px] lg:p-[40px] '>
          <h1 className='text-dark text-[30px] capitalize mb-[20px]'>
            Search Results
          </h1>
          <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5'>
            {searchResultsData.map((product, id) => (
              <Product key={id} product={product} />
            ))}
          </div>
          <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
        </div>
      </div>
    </div>
  )
}

export default SearchResults
