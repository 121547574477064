import React, { useState, useEffect, useRef } from 'react'
import { data } from '../../Constants'
import { Link } from 'react-router-dom'
import { BiDotsVertical } from 'react-icons/bi'
import utils from '../../Core/utils'
import DatePicker from 'react-datepicker'
import Pagination from '../../Components/Pagination'
import { parseISO } from 'date-fns'
import { motion, AnimatePresence } from 'framer-motion'
const Orders = () => {
  const [showOrderModal, setShowOrderModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState(data.Orders)
  const [filters, setFilters] = useState({ package_size: '', status: '' })
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  useEffect(() => {
    const filterData = () => {
      let filtered = data.Orders

      if (searchTerm) {
        const lowerCaseSearchTerm = searchTerm.toLowerCase()
        filtered = filtered.filter(
          order =>
            order.first_name.toLowerCase().includes(lowerCaseSearchTerm) ||
            order.last_name.toLowerCase().includes(lowerCaseSearchTerm) ||
            order.email.toLowerCase().includes(lowerCaseSearchTerm) ||
            order.item.toLowerCase().includes(lowerCaseSearchTerm) ||
            order.city.toLowerCase().includes(lowerCaseSearchTerm) ||
            order.phone_number.includes(searchTerm)
        )
      }

      if (filters.package_size) {
        filtered = filtered.filter(
          item => item.package_size === filters.package_size
        )
      }

      if (filters.status) {
        const isDeliverd = filters.status === 'true'

        if (filters.status === 'true') {
          filtered = filtered.filter(order => order.status === isDeliverd)
        } else if (filters.status === 'pending') {
          filtered = filtered.filter(order => order.status === 'pending')
        } else {
          filtered = filtered.filter(order => order.status === false)
        }
      }

      if (startDate || endDate) {
        filtered = filtered.filter(
          order =>
            !startDate ||
            (parseISO(order.date) >= startDate &&
              (!endDate || parseISO(order.date) <= endDate))
        )
      }
      setFilteredData(filtered)
    }

    filterData()
  }, [searchTerm, filters, startDate, endDate])

  // Date Picker
  const [dateSelect, setDateSelect] = useState(false)
  const selectDateRef = useRef()
  const selectDateButtonRef = useRef(null)
  const toggleMenu = () => {
    setDateSelect(prevIsMenuOpen => !prevIsMenuOpen)
  }
  useEffect(() => {
    const handleOutsideClick = event => {
      // Close modal if click is outside of modalRef
      if (
        selectDateRef.current &&
        !selectDateRef.current.contains(event.target) &&
        !selectDateButtonRef.current.contains(event.target)
      ) {
        setDateSelect(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  // Pagination
  const [currentPage, setCurrentPage] = useState(0)
  const productsPerPage = 10 // This can be changed to set the items to display per  page

  const pageCount = Math.ceil(filteredData.length / productsPerPage)

  const handlePageChange = selectedPage => {
    setCurrentPage(selectedPage)
  }

  const ordersDatta = filteredData.slice(
    currentPage * productsPerPage,
    (currentPage + 1) * productsPerPage
  )

  return (
    <div className='h-full w-full flex flex-col px-2 md:px-4 py-5 bg-white'>
      <h1 className='text-[30px] font-bold'>Orders #{data.Orders.length}</h1>
      <div className='flex gap-4 items-center justify-between'>
        <div className='flex items-center gap-4'>
          <select
            name=''
            onChange={e =>
              setFilters({ ...filters, package_size: e.target.value })
            }
            className='p-2 e w-[150px] hover:bg-secondary/90  px-4 py-2 outline-none  bg-secondary text-white border-r-4 border-secondary/80 cursor-pointer '
          >
            <option value=''>Package Size</option>
            <option value='250g'>250g</option>
            <option value='500g'>500g</option>
            <option value='750g'>750g</option>
            <option value='1kg'>1kg</option>
          </select>
          <select
            name='status'
            id=''
            onChange={e => setFilters({ ...filters, status: e.target.value })}
            className='p-2 e w-[150px]  hover:bg-secondary/90  px-4 py-2 outline-none  bg-secondary text-white border-r-4 border-secondary/80 cursor-pointer '
          >
            <option value=''>Status</option>
            <option value='true'>Deliverd</option>
            <option value='pending'>pending</option>
            <option value='false'>Not Deliverd</option>
          </select>

          {/* Date Select */}
          <div className='flex flex-col  gap-3 relative'>
            <button
              ref={selectDateButtonRef}
              onClick={toggleMenu}
              className='p-2 e w-[150x]  hover:bg-secondary/90  px-4 py-3 outline-none  bg-secondary text-white border-r-4 border-secondary/80 cursor-pointer '
            >
              Select Date
            </button>
            {dateSelect ? (
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  ref={selectDateRef}
                  className={`absolute bg-white top-full w-max shadow-xl flex px-2 py-3 flex-col md:flex-row rounded-lg gap-5 left-0 md:right-0 `}
                >
                  <div className='flex flex-col'>
                    <p>Start Date:</p>
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      inline
                      className='px-4 py-1 cursor-pointer font-bold text-[18px] bg-secondary/10'
                    />
                  </div>
                  <div className='flex flex-col'>
                    <p>End Date: </p>
                    <DatePicker
                      inline
                      selected={endDate}
                      onChange={date => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      className='px-4 py-1 cursor-pointer font-bold text-[18px] bg-secondary/10'
                    />
                  </div>
                </motion.div>
              </AnimatePresence>
            ) : (
              <></>
            )}
          </div>

          <input
            type='text'
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder='Name, Email, city, Item'
            className='w-[250px]'
          />
        </div>

        <button
          type='button'
          onClick={() => utils.exportToCSV(data.Orders, 'Ordes')}
          className='p-2 e w-[150px]  hover:bg-secondary/90  px-4 py-3 bg-secondary text-white'
        >
          Export CSV
        </button>
      </div>
      <table className='w-full mt-3  order-table' id='order-table'>
        <thead className=''>
          <tr>
            <td>No</td>
            <td>Date</td>
            <td>Person Info</td>
            <td>Address</td>
            <td>Delivery Instruction</td>
            <td>Item</td>
            <td>Package size</td>
            <td>Qunatity</td>
            <td>Discount</td>
            <td>Price</td>
            <td>Status</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {ordersDatta.map((order, key) => (
            <tr key={key} onClick={() => setShowOrderModal(!showOrderModal)}>
              <td className='max-w-[20px]'>{key + 1}</td>
              <td className='max-w-[20px]'>{order.date}</td>
              <td className=' items-center gap-2 min-w-[150px]'>
                {order.first_name}
                {order.last_name} <br />
                {order.email} <br />
                {order.phone_number}
              </td>
              <td>
                {order.city} <br />
                {order.address}
              </td>
              <td>{order.delivery_instruction}</td>
              <td>{order.item}</td>
              <td>{order.package_size}</td>
              <td>{order.quantity}</td>
              <td>{order.discount_code}</td>
              <td>{order.price}</td>
              <td>
                {order.status === true ? (
                  <p className='text-green-800 border-[1px]  text-center border-green-500 bg-green-400/30 rounded-md p-1 md:p-2 font-bold'>
                    Deliverd
                  </p>
                ) : order.status === 'pending' ? (
                  <p className='text-orange-800 border-[1px] text-center border-orange-400 bg-orange-400/30 rounded-md  font-bold text-[13px] md:text-[16px] p-1 md:p-2'>
                    pending
                  </p>
                ) : (
                  <p className='text-red-800 border-[1px] text-center border-red-400 bg-red-400/30 rounded-md  font-bold text-[13px] md:text-[16px] p-1 md:p-2'>
                    Not Deliverd
                  </p>
                )}
              </td>
              <td className='relative  w-[0px]'>
                <div className='relative store-action-menu  w-max'>
                  <BiDotsVertical size={20} className='' />{' '}
                  <ul className='absolute top-0 -right-[20px] z-4 bg-white rounded-md shadow-md p-2 min-w-[170px] store-menus'>
                    {order.status === true ? (
                      <li className='flex text-secondary px-2 py-1 rounded-lg hover:bg-secondary/10 w-full'>
                        <button className={'w-full'}>Mark UnDeliverd</button>
                      </li>
                    ) : order.status === 'pending' ? (
                      <>
                        <li className='flex text-secondary px-2 py-1 rounded-lg hover:bg-secondary/10 w-full'>
                          <button className={'w-full'}>Mark Delvierd</button>
                        </li>
                        <li className='flex text-secondary px-2 py-1 rounded-lg hover:bg-secondary/10 w-full'>
                          <button className={'w-full'}>Mark UnDelvierd</button>
                        </li>
                      </>
                    ) : (
                      <li className='flex text-secondary px-2 py-1 rounded-lg hover:bg-secondary/10 w-full'>
                        <button className={'w-full'}>Mark Deliverd</button>
                      </li>
                    )}
                    <li className='flex text-secondary px-2 py-1 rounded-lg hover:bg-secondary/10 w-full'>
                      <button className={'w-full'}>Archive Order</button>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
    </div>
  )
}

export default Orders
