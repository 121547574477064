import axios from 'axios'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import useGlobal from './global'
import { saveAs } from 'file-saver'

function checkPhoneNumber (phoneNumber) {
  const phoneRegex = /^\+?[1-9]\d{1,14}$/
  return phoneRegex.test(phoneNumber)
}

function checkEmail (email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

const getCountryCode = async () => {
  try {
    const response = await axios.get(`https://ipapi.co/json/`)
    return response.data.country_code
  } catch (error) {
    console.error('Error getting the country code: ', error)
    return null
  }
}

const generatePDF = (tableID, columns, title) => {
  const table = document.getElementById(tableID)

  const rows = []
  for (let i = 1; i < table.rows.length; i++) {
    const row = []
    for (let j = 0; j < columns.length; j++) {
      row.push(table.rows[i].cells[j].innerText)
    }
    rows.push(row)
  }

  const doc = new jsPDF()

  doc.text(title, 10, 10)
  doc.autoTable({
    head: [columns],
    body: rows
  })

  // Save the PDF
  doc.save(`${title}.pdf`)
}

// Trigger toast Message
const triggerToast = (message, status) => {
  const addToast = useGlobal.getState().addToast
  addToast(message, status)
}

const exportToCSV = (data, filename) => {
  const headers = Object.keys(data[0]).slice(0, -1)
  const rows = data.map(row => {
    return headers.map(header => {
      const cell = row[header]
      if (Array.isArray(cell)) {
        return `"${cell.join(',')}"`
      }
      return cell
    })
  })

  let csvContent = ''
  csvContent += headers.join(',') + '\n'

  rows.forEach(rowArray => {
    csvContent += rowArray.join(',') + '\n'
  })

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  saveAs(blob, `${filename}.csv`)
}

export default {
  checkEmail,
  checkPhoneNumber,
  getCountryCode,
  generatePDF,
  triggerToast,
  exportToCSV
}
