import React, { useState, useEffect, useRef } from 'react'
import { BiSearch, BiCart } from 'react-icons/bi'
import { NavLink, useNavigate, Link, useLocation } from 'react-router-dom'
import { FaRegUser } from 'react-icons/fa'
import { LuChevronDown } from 'react-icons/lu'
import {
  PiList,
  PiSignOutLight,
  PiUserLight,
  PiShoppingCartSimpleLight,
  PiMagnifyingGlassLight
} from 'react-icons/pi'
import useGlobal from '../Core/global'
import { Button, Input } from '@material-tailwind/react'
import NavBarLink from './NavLink'
import { motion, AnimatePresence } from 'framer-motion'

const SearchForm = ({ setSearched, handleSearch, setShowSearch }) => {
  return (
    <div className=' flex bg-primary items-start justify-center  fixed h-[200px] w-full left-0 top-0 z-[8] '>
      <div className='bg-primary  flex flex-col items-start  w-full h-full py-[70px] px-[30px] justify-between'>
        <h2>Search</h2>
        <form
          className='border-[1px] border-b-secondary w-full'
          onSubmit={e => handleSearch(e)}
        >
          <Input
            className='border-none w-full text-xl placeholder:text-[50px] p-4'
            variant='standard'
            type='text'
            name='searched'
            placeholder='Search'
            onChange={e => setSearched(e.target.value)}
          />
        </form>
      </div>
    </div>
  )
}
const NavBar = () => {
  const navbarRef = useRef(null)
  const cartItems = useGlobal(state => state.cartItems)
  const [totalQuantity, setTotalQuantity] = useState()
  const authenticated = useGlobal(state => state.authenticated)
  const admin = useGlobal(state => state.admin)
  const logout = useGlobal(state => state.logout)
  const [showSearch, setShowSearch] = useState(false)
  const [searched, setSearched] = useState('')
  const [mobilemenu, setMobileMenu] = useState(false)
  const [showProfileMenu, setShowProfileOption] = useState(false)

  const navigate = useNavigate()

  const handleSearch = e => {
    e.preventDefault()
    setShowSearch(false)
    setMobileMenu(false)
    navigate(`/search/${searched}`)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (navbarRef.current) {
        if (window.scrollY > navbarRef.current.offsetTop) {
          navbarRef.current.classList.add('sticky')
          document.querySelector('.outlet-container').classList.add('sticky')
        } else {
          navbarRef.current.classList.remove('sticky')
          document.querySelector('.outlet-container').classList.remove('sticky')
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    let total = 0
    cartItems.forEach(item => (total += item.quantity))
    setTotalQuantity(total)
  }, [localStorage.getItem('cartItems')])

  const mobileNavRef = useRef()
  const profileRef = useRef()

  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        mobileNavRef.current &&
        !mobileNavRef.current.contains(event.target)
      ) {
        setMobileMenu(false)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  const searchRef = useRef()
  const mobileSearchRef = useRef()
  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        mobileSearchRef.current &&
        !mobileSearchRef.current.contains(event.target)
      ) {
        setShowSearch(false)
        console.log(!searchRef.current.contains(event.target))
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  // Framer varaints
  const modalVariants = {
    hidden: {
      x: '-100vh'
    },
    visible: {
      x: 0,
      transition: {
        type: 'tween',
        duration: 0.5
      }
    },
    exit: {
      x: '-100vh',
      transition: {
        type: 'tween',
        duration: 0.5,
        delay: 0.3
      }
    }
  }

  const searchModalVariants = {
    hidden: {
      y: '-30vh'
    },
    visible: {
      y: 0,
      transition: {
        type: 'tween',
        duration: 0.5
      }
    },
    exit: {
      y: '-30vh',
      transition: {
        type: 'tween',
        duration: 0.5,
        delay: 0.3
      }
    }
  }
  const location = useLocation()
  const isHomePage = location.pathname === '/'
  return (
    <div
      ref={navbarRef}
      className={`${
        isHomePage ? 'fixed  home-navbar top-0 left-0 p-0 ' : 'flex'
      } navbar-container h-[100px] w-full `}
    >
      <div className='navbar-wrapper w-full h-[100px] '>
        <div className='max-w-[1440px] m-auto px-[20px] flex h-full justify-center flex-grow  transition-all duration-500 items-center relative'>
          <div className='flex items-center gap-10'>
            <ul className='gap-4 items-center hidden absolute md:flex md:relative  '>
              <li>
                <NavBarLink to='/shop'>Shop</NavBarLink>
              </li>
              <li>
                <NavBarLink to='/brands'>Brands</NavBarLink>
              </li>
              <li>
                <NavBarLink to='/about-us'>About Us</NavBarLink>
              </li>
            </ul>
          </div>
          <div className=' flex-1 hidden text-cold bg-transparent  py-1 md:flex items-center justify-center pr-0 md:pr-[100px] '>
            <NavLink to='/'>
              <h1 className=' tfont-bold text-[20px] md:text-[30px]'>
                ABOL COFFEE
              </h1>
            </NavLink>
          </div>

          <ul className='gap-10 items-center hidden absolute md:flex md:relative  '>
            <div className='flex items-center justify-center gap-4'>
              <li className='relative text-secondary'>
                <NavLink>
                  <PiMagnifyingGlassLight
                    size={25}
                    onClick={() => setShowSearch(!showSearch)}
                  />
                </NavLink>
                <AnimatePresence>
                  {showSearch && (
                    <motion.div
                      className=' flex bg-primary items-start justify-center  fixed h-[200px] w-full left-0 top-0 z-[8] '
                      variants={searchModalVariants}
                      initial='hidden'
                      animate='visible'
                      exit='exit'
                      ref={searchRef}
                    >
                      <SearchForm
                        handleSearch={handleSearch}
                        setSearched={setSearched}
                        setShowSearch={setShowSearch}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </li>
              <li className='relative'>
                <NavLink
                  to='/cart'
                  className='p-2 text-secondary flex items-center rounded-full justify-center'
                  style={({ isActive }) => {
                    return isActive
                      ? {
                          backgroundColor: 'rgba(111, 64, 16, 0.1)'
                        }
                      : {}
                  }}
                >
                  <PiShoppingCartSimpleLight size={25} />
                </NavLink>
                {totalQuantity > 0 && (
                  <p className='absolute px-2 text-[12px] font-sans font-bold bg-red-500 rounded-full text-primary -top-1 -right-2 z-2'>
                    {totalQuantity}
                  </p>
                )}
              </li>
              {authenticated ? (
                <li className='relative profile-menu'>
                  <span
                    className={'flex items-end cursor-pointer text-secondary'}
                  >
                    <PiUserLight size={23} />
                  </span>

                  <div className='absolute z-20 profile-menus -right-[10px]'>
                    <ul className=' flex gap-3 items-start bg-primary px-2 py-2 w-[170px] flex-col  shadow-lg'>
                      <li className=' text-secondary px-2 py-1 flex  hover:bg-secondary/10 w-full'>
                        <NavLink
                          className={'font-bold  w-full'}
                          to={'/my-account'}
                        >
                          My Account
                        </NavLink>
                      </li>

                      {admin && (
                        <li className='flex text-secondary px-2 py-1 hover:bg-secondary/10 w-full'>
                          <NavLink className={'w-full font-bold'} to={'admin'}>
                            Admin
                          </NavLink>
                        </li>
                      )}
                      <li className='text-dark flex px-2 py-1 rounded-lg hover:bg-dark/10 w-full'>
                        <Button
                          className={
                            'w-full text-start bg-accent font-serif text-[17px] flex item gap-5 capitalize py-2 rounded-none '
                          }
                          onClick={() => {
                            logout()
                          }}
                        >
                          <PiSignOutLight size={25} /> Logout
                        </Button>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : (
                <li>
                  <Link
                    to='/login'
                    className={'flex items-end cursor-pointer text-secondary'}
                  >
                    <PiUserLight size={23} />
                  </Link>
                </li>
              )}
            </div>
          </ul>

          {/* Mobile Nav */}
          <div className=' md:hidden relative  mobile-nav flex w-full items-center justify-between'>
            <span className='cursor-pointer '>
              <PiList
                className='cursor-pointer'
                size={25}
                onClick={() => setMobileMenu(!mobilemenu)}
              />
            </span>
            <NavLink to='/'>
              <h1 className=' font-bold text-[25px] ml-[30px] md:text-[35px]'>
                ABOL COFFEE
              </h1>
            </NavLink>
            <ul className='flex items-centergap-3 '>
              <li className='relative flex w-full'>
                <span className='cursor-pointer  p-2'>
                  <PiMagnifyingGlassLight
                    size={25}
                    onClick={() => setShowSearch(!showSearch)}
                  />
                </span>
                <AnimatePresence>
                  {showSearch && (
                    <motion.div
                      className=' flex bg-primary items-start justify-center  fixed h-[200px] w-full left-0 top-0 z-[8] '
                      variants={searchModalVariants}
                      initial='hidden'
                      animate='visible'
                      exit='exit'
                      ref={mobileSearchRef}
                    >
                      <SearchForm
                        handleSearch={handleSearch}
                        setSearched={setSearched}
                        setShowSearch={setShowSearch}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </li>
              <li className=' w-full flex'>
                <NavLink
                  to='/cart'
                  className='flex items-center rounded-full justify-center p-2 relative'
                  style={({ isActive }) => {
                    return isActive
                      ? {
                          color: '#674F04 ',
                          backgroundColor: 'rgba(111, 64, 16, 0.1)'
                        }
                      : {}
                  }}
                  onClick={() => setMobileMenu(false)}
                >
                  <PiShoppingCartSimpleLight size={25} />
                  {totalQuantity > 0 && (
                    <p className='absolute px-2 text-[12px] font-sans font-bold  bg-red-500 rounded-full text-primary -top-2 -right-3 z-2'>
                      {totalQuantity}
                    </p>
                  )}
                </NavLink>
              </li>
            </ul>
          </div>

          <AnimatePresence>
            {mobilemenu && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className={` 'flex flex-col  mobile-menus gap-5 items-start fixed top-0 left-0  w-full h-screen bg-secondary/10 `}
              >
                <motion.ul
                  variants={modalVariants}
                  initial='hidden'
                  animate='visible'
                  exit='exit'
                  ref={mobileNavRef}
                  className={` gap-3 items-start flex flex-col  text-[17px] absolute  p-4 py-8 top-0 left-0 w-3/4 h-screen bg-primary overflow-y-scroll`}
                >
                  <li className='flex w-full '>
                    <NavLink
                      className={'w-full'}
                      to='/shop'
                      onClick={() => setMobileMenu(false)}
                    >
                      <p> Shop</p>
                    </NavLink>
                  </li>
                  <li className='flex w-full '>
                    <NavLink
                      className={'w-full'}
                      to='/brands'
                      onClick={() => setMobileMenu(false)}
                    >
                      <p> Brands</p>
                    </NavLink>
                  </li>
                  <li className='flex w-full '>
                    <NavLink
                      className={'w-full'}
                      to='/about-us'
                      onClick={() => setMobileMenu(false)}
                    >
                      <p> About Us</p>
                    </NavLink>
                  </li>

                  {authenticated ? (
                    <li className=' relative flex w-full'>
                      <div
                        className={
                          'w-full flex  items-center gap-4  focus:bg-four/10 justify-between'
                        }
                      >
                        <p className='flex items-center gap-3 text-secondary'>
                          <PiUserLight size={20} /> Profile
                        </p>
                        <LuChevronDown
                          size={25}
                          onClick={() => setShowProfileOption(!showProfileMenu)}
                        />
                      </div>

                      {showProfileMenu ? (
                        <div
                          className=' absolute z-20 w-full top-[50px] '
                          ref={profileRef}
                        >
                          <ul className=' flex gap-3 items-start bg-primary px-2 py-2 w-full flex-col rounded-lg  shadow-md'>
                            <li className='flex text-dark px-2 py-1 rounded-lg hover:bg-dark/10 w-full'>
                              <NavLink
                                to='/my-account'
                                onClick={() => setMobileMenu(false)}
                                style={{ color: '#3a2618' }}
                                className={'w-full font-bold'}
                              >
                                My Account
                              </NavLink>
                            </li>

                            {admin ? (
                              <li className='flex text-dark px-2 py-1 rounded-lg hover:bg-dark/10 w-full'>
                                <NavLink
                                  style={{ color: '#3a2618' }}
                                  className={'w-full font-bold'}
                                  onClick={() => setMobileMenu(false)}
                                  to={'admin'}
                                >
                                  Admin
                                </NavLink>
                              </li>
                            ) : (
                              <></>
                            )}
                            <li className='text-dark px-2 py-1 rounded-lg hover:bg-dark/10 w-full'>
                              <Button
                                className='bg-accent w-full capitalize font-serif py-3 rounded-none hover:bg-olivegreen'
                                onClick={() => {
                                  logout()
                                  setMobileMenu(false)
                                }}
                              >
                                Logout
                              </Button>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <></>
                      )}
                    </li>
                  ) : (
                    <li className='flex w-full'>
                      <Link
                        to='/login'
                        onClick={() => setMobileMenu(false)}
                        className={
                          'flex items-end w-full gap-2  text-secondary'
                        }
                      >
                        <PiUserLight size={23} /> <p>Login / Register</p>
                      </Link>
                    </li>
                  )}
                </motion.ul>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}

export default NavBar
