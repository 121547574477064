import React, { useState, useEffect, useRef } from 'react'
import Product from '../Components/Product'
import { data } from '../Constants'
import Filter from '../Components/Filter'
import { PiXLight, PiFadersHorizontal } from 'react-icons/pi'
import Pagination from '../Components/Pagination'
import useGlobal from '../Core/global'
import { Button } from '@material-tailwind/react'
import { motion, AnimatePresence } from 'framer-motion'
const size_filter = ['250g', '500g', '750g', '1kg']
const roast_level_filter = ['Light', 'Medium', 'Medium-Dark', 'Dark']
const origin_filter = [
  'Yirga cheffe',
  'Harrar',
  'Sidama',
  'Jimma',
  'Giji',
  'Bale',
  'Wellega'
]
const Shop = () => {
  const products = data.Store
  const [originFilters, setOriginFilters] = useState([])
  const [roastFilters, setRoastFilters] = useState([])
  const [maxPrice, setMaxPrice] = useState('')
  const [minPrice, setMinPrice] = useState('')

  const country = useGlobal(state => state.country)
  const applyFilters = () => {
    return products.filter(product => {
      // Check size filter

      // Check origin filter
      if (originFilters.length > 0 && !originFilters.includes(product.origin)) {
        return false
      }
      // Check Roast Level filter
      if (
        roastFilters.length > 0 &&
        !roastFilters.includes(product.roast_level)
      ) {
        return false
      }
      // Check price filter
      let price = country === 'ET' ? product.price_birr : product.price_usd
      if (minPrice !== '' && price < minPrice) {
        return false
      }
      if (maxPrice !== '' && price > maxPrice) {
        return false
      }
      return true
    })
  }

  // Handle origin checkbox change
  const handleOriginChange = event => {
    const origin = event.target.value
    if (originFilters.includes(origin)) {
      setOriginFilters(originFilters.filter(item => item !== origin))
    } else {
      setOriginFilters([...originFilters, origin])
    }
  }

  // Handle Roast Level checkbox change
  const HandleRoastLevelChange = event => {
    const roast_level = event.target.value
    if (roastFilters.includes(roast_level)) {
      setRoastFilters(roastFilters.filter(item => item !== roast_level))
    } else {
      setRoastFilters([...roastFilters, roast_level])
    }
  }

  // Handle price  Range change
  const handleMinPriceChange = e => {
    setMinPrice(e.target.value)
  }
  const handleMaxPriceChange = e => {
    setMaxPrice(e.target.value)
  }

  const filteredProducts = applyFilters()
  const [showFilters, setShowFilters] = useState(false)

  // Pagination
  const [currentPage, setCurrentPage] = useState(0)
  const productsPerPage = 6 // This chan be changed

  const pageCount = Math.ceil(filteredProducts.length / productsPerPage)

  const handlePageChange = selectedPage => {
    setCurrentPage(selectedPage)
  }

  const displayProducts = filteredProducts.slice(
    currentPage * productsPerPage,
    (currentPage + 1) * productsPerPage
  )
  useEffect(() => {
    document.title = 'Shop | Abol Coffee'
  })
  // Framer varaints

  const filterModalRef = useRef()
  const filterVariants = {
    hidden: {
      x: '-100vh'
    },
    visible: {
      x: 0,
      transition: {
        type: 'tween',
        duration: 0.5
      }
    },
    exit: {
      x: '-100vh',
      transition: {
        type: 'tween',
        duration: 0.5,
        delay: 0.3
      }
    }
  }
  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        filterModalRef.current &&
        !filterModalRef.current.contains(event.target)
      ) {
        setShowFilters(false)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  return (
    <div className='w-full min-h-screen'>
      <div className='m-auto h-auto max-w-[1440px] p-[20px] md:p-[30px] lg:px-[40px] flex flex-col items-start gap-3 md:gap-5 py-[25px]'>
        <Button
          onClick={() => setShowFilters(!showFilters)}
          variant='text'
          className='  p-3 flex justify-center items-center gap-3 text-center rounded-[30px] bg-secondary/20 text-secondary font-serif text-[17px]  capitalize w-[170px] font-semibold border-none md:hidden'
        >
          <PiFadersHorizontal size={25} />
          {showFilters ? 'Hide Filters' : 'Show Filters'}
        </Button>

        <div className='flex items-start gap-3 md:gap-5 py-[25px]'>
          <motion.div
            className={` hidden md:flex  w-[350px]  flex-col gap-5  h-msx  md:h-full  `}
          >
            <div className='absolute top-[10px] right-[10px]'>
              <PiXLight
                size={25}
                className='cursor-pointer p-1 hover:bg-four/10 rounded-full md:hidden'
                onClick={() => setShowFilters(!showFilters)}
              />
            </div>
            <div>
              <p className='text-[18px] mb-[5px] font-semibold'>Price Range</p>
              <div className='flex items-center gap-2 max-w-[200px]'>
                <input
                  variant='static'
                  type='number'
                  value={minPrice}
                  onChange={e => handleMinPriceChange(e)}
                  placeholder='Min Price'
                  className='w-[100px] bg-transparent px-3 py-3 placeholder:text-secondary transition-all outline-[2px] outline-offset-0 outline-transparent duration-300 border-[1px] border-secondary/50 focus:outline-secondary'
                />
                <input
                  variant='static'
                  type='number'
                  value={maxPrice}
                  onChange={e => handleMaxPriceChange(e)}
                  placeholder='Max Price'
                  className='w-[100px] bg-transparent px-3 py-3 placeholder:text-secondary transition-all outline-[2px] outline-offset-0 outline-transparent duration-300 border-[1px] border-secondary/50 focus:outline-secondary'
                />
              </div>
            </div>

            <div>
              <p className='text-[18px] font-semibold'>Roast Level</p>
              {roast_level_filter.map((roast_level, id) => (
                <Filter
                  key={id}
                  text={roast_level}
                  action={e => HandleRoastLevelChange(e)}
                />
              ))}
            </div>
            <div>
              <p className='text-[18px] font-semibold mb-[5px]'>Origin</p>
              {origin_filter.map((origin, id) => (
                <Filter key={id} text={origin} action={handleOriginChange} />
              ))}
            </div>
          </motion.div>
          <AnimatePresence>
            {showFilters && (
              <motion.div className=' shop-filter w-full bg-secondary/40 fixed z-[10000] top-0 left-0 h-screen'>
                <motion.div
                  ref={filterModalRef}
                  variants={filterVariants}
                  initial='hidden'
                  animate='visible'
                  exit='exit'
                  className={`p-[50px] pt-[100px] overflow-y-scroll h-screen  w-[350px] bg-primary flex-col gap-5  h-msx  md:h-full  `}
                >
                  <div className='absolute top-[10px] right-[10px]'>
                    <PiXLight
                      size={25}
                      className='cursor-pointer p-1 hover:bg-four/10 rounded-full md:hidden'
                      onClick={() => setShowFilters(!showFilters)}
                    />
                  </div>
                  <div>
                    <p className='text-[18px] mb-[5px] font-semibold'>
                      Price Range
                    </p>
                    <div className='flex items-center gap-2 max-w-[200px]'>
                      <input
                        variant='static'
                        type='number'
                        value={minPrice}
                        onChange={e => handleMinPriceChange(e)}
                        placeholder='Min Price'
                        className='w-[100px] bg-transparent px-3 py-3 placeholder:text-secondary transition-all outline-[2px] outline-offset-0 outline-transparent duration-300 border-[1px] border-secondary/50 focus:outline-secondary'
                      />
                      <input
                        variant='static'
                        type='number'
                        value={maxPrice}
                        onChange={e => handleMaxPriceChange(e)}
                        placeholder='Max Price'
                        className='w-[100px] bg-transparent px-3 py-3 placeholder:text-secondary transition-all outline-[2px] outline-offset-0 outline-transparent duration-300 border-[1px] border-secondary/50 focus:outline-secondary'
                      />
                    </div>
                  </div>

                  <div>
                    <p className='text-[18px] font-semibold'>Roast Level</p>
                    {roast_level_filter.map((roast_level, id) => (
                      <Filter
                        key={id}
                        text={roast_level}
                        action={e => HandleRoastLevelChange(e)}
                      />
                    ))}
                  </div>
                  <div>
                    <p className='text-[18px] font-semibold mb-[5px]'>Origin</p>
                    {origin_filter.map((origin, id) => (
                      <Filter
                        key={id}
                        text={origin}
                        action={handleOriginChange}
                      />
                    ))}
                  </div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
          <motion.div
            initial={{ width: '100%' }}
            animate={{ width: '100%' }}
            className='w-full'
          >
            <div className='grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5'>
              {displayProducts.map((product, id) => (
                <Product key={id} product={product} />
              ))}
            </div>
            <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Shop
