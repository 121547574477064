import React, { useState } from 'react'
import { data } from '../../Constants'
import AddPriceModal from '../../Components/Admin/AddPriceModal'
import UpdatePriceModal from '../../Components/Admin/UpdatePriceModal'
import { useLocation } from 'react-router-dom'
const Pricing = () => {
  const location = useLocation()
  const item = location.state?.item

  const [showAddPriceModal, setShowAddPriceModal] = useState(false)
  const [showUpdatePriceModal, setShowUpdatePriceModal] = useState(false)
  return (
    <div className='h-full w-full flex flex-col px-2 md:px-4 py-5 bg-white'>
      <h1 className='text-[30px] font-bold'>Pricing</h1>

      <div className='flex  items-center gap-5 mt-5'>
        <button
          disabled={item ? false : true}
          onClick={() => setShowAddPriceModal(!showAddPriceModal)}
          type='button'
          className='p-2 e w-[150px]  hover:bg-secondary/90  px-4 py-2 bg-secondary text-white'
        >
          Add Price
        </button>
        <button
          disabled={item ? false : true}
          onClick={() => setShowUpdatePriceModal(!showUpdatePriceModal)}
          type='button'
          className='p-2 e w-[150px]  hover:bg-secondary/90  px-4 py-2 bg-secondary text-white'
        >
          Update Price
        </button>
      </div>
      <div className='w-full  mt-5 bg-secondary/10 border-secondary/20 border-[1px]'>
        <ul className='flex flex-col items-start gap-3'>
          <li className=' w-full flex  justify-start items-center gap-[50px]  px-3 py-2 border-[1px] border-b-secondary/30'>
            <p className='w-[130px] font-semibold'>Product Name</p>
            <p className=''>{item?.product_name}</p>
          </li>
          <li className=' w-full flex justify-start items-center gap-[50px]  px-3 py-2 border-[1px] border-b-secondary/30'>
            <p className=' w-[130px] font-semibold'>Brand Name</p>
            <p className=''>{item?.brand_name}</p>
          </li>
          <li className=' w-full flex justify-start items-center gap-[50px]  px-3 py-2 border-[1px] border-b-secondary/30'>
            <p className=' w-[130px] font-semibold'>Roast Level</p>
            <p className=''>{item?.roast_level}</p>
          </li>
          <li className=' w-full flex justify-start items-center gap-[50px]  px-3 py-2 border-[1px] border-b-secondary/30'>
            <p className=' w-[130px] font-semibold'>Availible Stock</p>
            <p className=''>{item?.available_stock}</p>
          </li>
        </ul>
        <li className=' w-full flex  gap-[20px] flex-col justify-start items-center   px-3 py-2 border-[1px] border-b-secondary/30'>
          <p className=' text-start w-full font-semibold'>Prices</p>

          <table>
            <thead>
              <tr>
                <td>NO</td>
                <td>Country</td>
                <td>Price</td>
                <td>Date Updated</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Ethiopia</td>
                <td>{item?.price_birr} ETB</td>
                <td>24-05-2024</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Kenya</td>
                <td> $ {item?.price_usd}</td>
                <td>24-05-2024</td>
              </tr>
              <tr>
                <td>3</td>
                <td>USA</td>
                <td>$ {item?.price_usd}</td>
                <td>24-05-2024</td>
              </tr>
            </tbody>
          </table>
        </li>
      </div>
      <AddPriceModal
        setShowAddPriceModal={setShowAddPriceModal}
        showAddPriceModal={showAddPriceModal}
        packageSizes={item.package_size}
      />
      <UpdatePriceModal
        showUpdatePriceModal={showUpdatePriceModal}
        setShowUpdatePriceModal={setShowUpdatePriceModal}
        packageSizes={item.package_size}
      />
    </div>
  )
}

export default Pricing
