import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import useGlobal from '../Core/global'
import ReactMarkdown from 'react-markdown'
import { PiPlus, PiMinus } from 'react-icons/pi'
import utils from '../Core/utils'
import { Button } from '@material-tailwind/react'
import Flickity from 'flickity'
import { PiCaretLeft, PiCaretRight, PiArrowDownLight } from 'react-icons/pi'
import { data } from '../Constants'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import ProductSlider from '../Components/Product'
const ProductDetail = () => {
  const { product_id } = useParams()
  const [product, setProduct] = useState()
  const [itemQuantity, setItemQuantity] = useState(1)
  const [selectedWeight, setSelectedWeight] = useState()
  const [selecteProcesssedTyoe, setSelectedProcessedType] = useState('Grounded')

  const addItemToCart = useGlobal(state => state.addItemToCart)
  const country = useGlobal(state => state.country)
  const [addtoCartError, setAddToCartError] = useState('')

  const plusQuantityRef = useRef()
  const minusQuantityRef = useRef()
  const handleSearchBrand = () => {
    let results = data.Store.filter(data => `${data.id}` === product_id)
    setProduct(results[0])
  }

  useEffect(() => {
    handleSearchBrand()
  }, [product_id])

  const handleMinusQuantity = () => {
    setItemQuantity(itemQuantity - 1 < 1 ? 1 : itemQuantity - 1)
  }
  const handlePlusQuantity = () => {
    if (itemQuantity > product?.available_stock) {
      plusQuantityRef.current.disabled = true
    } else {
      plusQuantityRef.current.disabled = false
    }
    setItemQuantity(itemQuantity + 1)
  }
  const addToCart = () => {
    if (itemQuantity > product?.available_stock) {
      setAddToCartError('This much stock is not availible!')
      utils.triggerToast('This much stock is not availible!', false)
    } else {
      addItemToCart({
        ...product,
        quantity: itemQuantity,
        package_size: selectedWeight,
        processed_type: selecteProcesssedTyoe
      })
      utils.triggerToast('Item added to cart', true)
      setAddToCartError('')
    }
  }
  useEffect(() => {
    document.title = `${product?.product_name} | Abol Coffee`
  })

  // Paginationy
  const [currentPage, setCurrentPage] = useState(0)
  const productsPerPage = 6 // This can be changed

  const pageCount = Math.ceil(data.Store.length / productsPerPage)

  const handlePageChange = selectedPage => {
    setCurrentPage(selectedPage)
  }

  // Dummy Markdown Text
  const markdownContent = `

## Description

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac quam et quam vehicula semper. Donec posuere risus at erat ultricies, a placerat odio volutpat.

### Procedure

1. **Lorem ipsum dolor** - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
2. **Consectetur adipiscing** - Proin ac quam et quam vehicula semper.
3. **Donec posuere** - Donec posuere risus at erat ultricies, a placerat odio volutpat.

### Storage

- **Phasellus** - Phasellus fermentum in, dolor.
- **Pellentesque** - Pellentesque facilisis.
- **Curabitur** - Curabitur id nisi id nunc tristique tincidunt.

### Conclusion

Sed vitae risus ligula. Aliquam posuere mauris sit amet urna varius, vel condimentum lacus condimentum. Vivamus ultrices, metus at interdum congue, enim augue pretium nisl, sit amet vehicula metus quam at nunc.
`
  return (
    <div className=' h-auto w-full '>
      {/* Product detail and Add to cart */}
      <div className=' m-auto max-w-[1440px] p-[20px] md:p-[30px] lg:p-[40px] flex flex-col   gap-0 md:gap-[50px] justify-between'>
        <div className='w-full items-center justify-between flex gap-[50px] flex-col md:flex-row'>
          <div className=' w-full flex flex-col items-start justify-start'>
            <h1 className='font-semibold text-[25px] sm:text-[30px] md:text-[45px]'>
              {product?.product_name}
            </h1>
            <div className='flex flex-col w-full h-auto mt-5'>
              <img src={product?.image} alt='' className=' object-contain' />
            </div>
          </div>
          <div className='m-0 md:mt-10 w-full md:w-[50%] h-max flex flex-col gap-5 items-start bg-transparent px-0 md:px-[20px] py-[40px]   rounded-lg  '>
            <h2 className='text-[40px] font-bold'>{product?.brand_name}</h2>

            <div className='flex  gap-10 items-center'>
              <h2 className='font-semibold'>Origin</h2>
              <p>Ethiopia, {product?.origin}</p>
            </div>

            <div className='w-max flex  gap-3'>
              <h2 className='font-semibold'>Roast Level</h2>
              <p className='font-semibold py-1 px-4 w-max bg-secondary/10 rounded-2xl border-[1px] border-primary/20'>
                {product?.roast_level}
              </p>
            </div>
            {product?.available_stock <= 10 && (
              <div className='flex gap-5 items-center justify-start '>
                <h2 className='font-semibold'>Availible Stock:</h2>

                <p className='font-serif text-[20px] flex items-center gap-2 text-center px-4 py-1 bg-red-400 rounded-md text-primary '>
                  #{product?.available_stock}
                  <span>
                    <PiArrowDownLight />
                  </span>
                </p>
              </div>
            )}

            {/* Processed Type Radio Buttons */}
            <div className='w-full space-y-2'>
              <h2 className='font-semibold'> Processed Type</h2>

              <ProcessedTypeButton
                processedTypes={product?.processed_type}
                selecteProcesssedTyoe={selecteProcesssedTyoe}
                setSelectedProcessedType={setSelectedProcessedType}
              />
            </div>
            {/* Package size Radio buttons */}
            <div className='w-full space-y-2'>
              <h2 className='font-semibold'> Package Size</h2>

              <PackageSizeButton
                packageSizes={product?.package_size}
                selectedWeight={selectedWeight}
                setSelectedWeight={setSelectedWeight}
              />
            </div>

            <div className='flex gap-5 items-center justify-start'>
              <h2 className='font-semibold'>Price:</h2>
              <p className=' font-serif text-[25px]'>
                <span className='text-[40px] font-semibold text-secondary'>
                  {country === 'ET'
                    ? itemQuantity * product?.price_birr + ' ETB'
                    : '$' + itemQuantity * product?.price_usd}
                </span>
              </p>
            </div>

            <div className='flex  justify-start items-start gap-[30px] w-full'>
              <div
                style={
                  addtoCartError
                    ? { borderColor: 'rgba(255, 0, 0, 0.753)' }
                    : {}
                }
                className='flex border-[1px]  border-secondary/40  hover:border-secondary items-center gap-0 h-[50px] w-max overflow-hidden border-dark/20 rounded-[50px] '
              >
                <button
                  ref={minusQuantityRef}
                  onClick={() => handleMinusQuantity()}
                  className={`${
                    itemQuantity === 1
                      ? 'bg-gray-200 cursor-not-allowed '
                      : 'bg-transparent hover:bg-four/20'
                  }  rounded-xl px-4 md:px-6 py-1 text-white font-bold  h-full`}
                >
                  <PiMinus color={itemQuantity === 1 ? 'white' : 'black'} />
                </button>
                <input
                  type='number'
                  value={itemQuantity}
                  className='text-[17px] md:text-[25px] font-bold h-full px-1 md:px-2 py-0 border-none min-w-[20px] outline-none bg-transparent'
                  onChange={e => {
                    setItemQuantity(
                      Number(e.target.value) < 1 ? 1 : Number(e.target.value)
                    )
                  }}
                />
                <button
                  ref={plusQuantityRef}
                  onClick={() => handlePlusQuantity()}
                  className={`${
                    itemQuantity > product?.available_stock
                      ? 'bg-gray-200 cursor-not-allowed '
                      : 'bg-transparent hover:bg-four/20'
                  }  rounded-xl px-4 md:px-6 py-1 text-white font-bold  h-full`}
                >
                  <PiPlus color='black' />
                </button>
              </div>
              <Button
                className='p-3 bg-secondary text-primary border-[2px] shadow-none  font-serif text-[17px] capitalize w-full hover:border-secondary hover:bg-transparent rounded-[50px] hover:text-secondary'
                onClick={() => addToCart()}
              >
                Add to Cart
              </Button>
            </div>
            {addtoCartError && <p className='text-red-400'>{addtoCartError}</p>}
          </div>
        </div>
        <div className='flex flex-col items-start gap-5 mt-5 w-full  md:w-[70%] lg:w-[60%]'>
          <ReactMarkdown>{markdownContent}</ReactMarkdown>
        </div>
      </div>
      {/* Related Products */}
      <div className='w-full h-auto '>
        <div className=' m-auto max-w-[1440px] p-[20px] md:p-[30px] lg:p-[40px]  '>
          <div className='flex flex-col gap-5 w-full'>
            <h1 className=' text-center md:text-start md:text-[50px] capitalize '>
              Related Products
            </h1>
            <RelatedProductsSlider />
          </div>
        </div>
      </div>
    </div>
  )
}

const PackageSizeButton = ({
  packageSizes,
  selectedWeight,
  setSelectedWeight
}) => {
  const handleChange = event => {
    setSelectedWeight(event.target.value)
  }

  useEffect(() => {
    if (packageSizes) {
      setSelectedWeight(packageSizes[0])
    }
  }, [packageSizes])

  return (
    <div className='flex items-center gap-3'>
      {packageSizes?.map((packageSize, key) => (
        <label
          key={key}
          className={`weight-radio-button ${
            selectedWeight === packageSize ? 'selected' : ''
          } text-[17px]`}
        >
          <input
            type='radio'
            name='weight'
            value={packageSize}
            checked={selectedWeight === packageSize}
            onChange={handleChange}
            hidden
          />
          {packageSize}
        </label>
      ))}
    </div>
  )
}
const ProcessedTypeButton = ({
  processedTypes,
  selecteProcesssedTyoe,
  setSelectedProcessedType
}) => {
  const handleChange = event => {
    setSelectedProcessedType(event.target.value)
  }

  useEffect(() => {
    if (processedTypes) {
      setSelectedProcessedType(processedTypes[0])
    }
  }, [processedTypes])
  return (
    <div className='flex items-center gap-3'>
      {processedTypes?.map((processedType, key) => (
        <label
          key={key}
          className={`weight-radio-button ${
            selecteProcesssedTyoe === processedType ? 'selected' : ''
          } text-[17px]`}
        >
          <input
            type='radio'
            name='weight'
            value={processedType}
            checked={selecteProcesssedTyoe === processedType}
            onChange={handleChange}
            hidden
          />
          {processedType}
        </label>
      ))}
    </div>
  )
}

const RelatedProductsSlider = () => {
  const country = useGlobal(state => state.country)
  const flickityRef = useRef(null)
  const flkty = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  var options = {
    initialIndex: 0,
    cellAlign: 'left',
    wrapAround: false,
    pageDots: false,
    imagesLoaded: true,
    prevNextButtons: false,
    selectedAttraction: 0.02,
    friction: 0.2,
    autoPlay: 3000,
    contain: true
  }

  if (matchMedia('screen and (min-width: 768px)').matches) {
    options.groupCells = true
    options.groupCells = 1
  }
  useEffect(() => {
    flkty.current = new Flickity(flickityRef.current, options)

    flkty.current.on('change', index => {
      setCurrentSlide(index)
    })

    return () => {
      flkty.current.destroy()
    }
  }, [])
  const handlePrev = () => {
    if (flkty.current) {
      flkty.current.previous()
    }
  }

  const handleNext = () => {
    if (flkty.current) {
      flkty.current.next()
    }
  }
  return (
    <motion.div
      className='slider-container relative w-full h-[500px]  '
      initial={{ y: 150, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 1.5, delay: 0.6 }}
    >
      <div className='flickity-slider ' ref={flickityRef}>
        {data.Store.map((item, index) => {
          if (index > 7) return null
          return (
            <Link
              key={index}
              className='carousel-cell w-full shadow-md md:w-[300px] rounded-sm overflow-hidden flex bg-secondary/5 hover:bg-secondary/10 hover:shadow-2xl transition-all duration-300 h-[400px] group flex-col items-start gap-5  mr-[40px] my-[50px] border-[1px] border-secondary/5'
              to={`/shop/${item.id}`}
            >
              <div className='h-[250px] w-full overflow-hidden transform transition-transform duration-500 ease-in-out group-hover:scale-110 '>
                <img
                  src={item.image}
                  className='object-cover transform  mix-blend-darken'
                />
              </div>
              <div className=' px-4 flex flex-col gap-5'>
                <div>
                  <h2 className='text-secondary text-[20px] font-bold'>
                    {item.product_name}
                  </h2>

                  <div className='flex items-center justify-between'>
                    <p className='sm:px-3 sm:text-[15px] w-max py-1 px-4 bg-secondary/10 rounded-2xl'>
                      {item.roast_level}
                    </p>

                    <h2 className='font-bold'>
                      {country === 'ET'
                        ? item.price_birr + ' ETB'
                        : '$' + item.price_usd}
                    </h2>
                  </div>
                </div>
                {item.available_stock <= 10 && (
                  <div className='bg-red-400 px-3 w-max rounded-md '>
                    <p className='text-primary'>
                      Stock: #{item.available_stock}
                    </p>
                  </div>
                )}
              </div>
            </Link>
          )
        })}
      </div>
      <button
        onClick={handlePrev}
        className='absolute top-1/2 left-[20px] transform  -translate-y-1/2 flex items-center justify-center bg-accent/10  hover:bg-accent rounded-full  w-[60px] h-[60px]'
      >
        <PiCaretLeft size={35} color='white' />
      </button>
      <button
        onClick={handleNext}
        className='absolute top-1/2 right-[20px] transform -translate-y-1/2 flex items-center justify-center bg-accent/10 hover:bg-accent rounded-full   w-[60px] h-[60px]'
      >
        <PiCaretRight size={35} color='white' />
      </button>
    </motion.div>
  )
}
export default ProductDetail
