import images from './images'
const Store = [
  {
    id: 0,
    product_name: 'Hadero Coffee Light Roast ',
    brand_name: 'Hadero Coffee',
    price_usd: 15.99,
    price_birr: 850,

    roast_level: 'Light',
    origin: 'Yirga cheffe',
    processed_type: ['Grounded', 'Not Grounded'],
    package_size: ['500g', '1kg'],
    available_stock: 3,
    image: images.hadero_coffee
  },
  {
    id: 1,
    product_name: 'Tomoca Coffee Medium Roast Sidama Coffee',
    brand_name: 'Tomoca Coffee',
    price_usd: 13.99,
    price_birr: 750,

    roast_level: 'Medium',
    origin: 'Sidama',
    processed_type: ['Grounded', 'Not Grounded'],
    package_size: ['250g', '500g', '1kg'],
    available_stock: 200,
    image: images.tomoca_coffee
  },
  {
    id: 2,
    product_name: "Kaldi's Coffee Dark Roast Harrar Coffee",
    brand_name: "Kaldi's Coffee",
    price_usd: 17.5,
    price_birr: 930,
  
    roast_level: 'Dark',
    origin: 'Harrar',
    processed_type: ['Grounded', 'Not Grounded'],
    package_size: ['250g', '500g'],
    available_stock: 150,
    image: images.kaldis_coffee
  },
  {
    id: 3,
    product_name: 'Enabe Coffee Medium-Dark Ground Harrar Coffee',
    brand_name: 'Enabe Coffee',
    price_usd: 12.99,
    price_birr: 690,

    roast_level: 'Medium-Dark',
    origin: 'Jimma',
    processed_type: ['Grounded', 'Not Grounded'],
    package_size: ['250g', '500g', '750g', '1kg'],
    available_stock: 5,
    image: images.enabe_coffee
  },
  {
    id: 4,
    product_name: "Kaldi's Coffee Light Roast Harrar Coffee",
    brand_name: "Kaldi's Coffee",
    price_usd: 19.99,
    price_birr: 1050,
  
    roast_level: 'Light',
    origin: 'Harrar',
    processed_type: ['Grounded', 'Not Grounded'],
    package_size: ['250g', '500g', '750g'],
    available_stock: 100,
    image: images.kaldis_coffee
  },
  {
    id: 5,
    product_name: 'Alem Coffee Light Roast organic Guji Coffee',
    brand_name: 'Alem Coffee',
    price_usd: 16.75,
    price_birr: 880,

    roast_level: 'Medium',
    origin: 'Guji',
    processed_type: ['Grounded', 'Not Grounded'],
    package_size: ['250g', '500g'],
    available_stock: 180,
    image: images.alem_coffee
  },
  {
    id: 6,
    product_name: 'Cubico Light Roast organic Yirga chaffee Coffee',
    brand_name: 'Cubico Coffee',
    price_usd: 14.99,
    price_birr: 790,

    roast_level: 'Light',
    origin: 'Yirga cheffe',
    processed_type: ['Grounded', 'Not Grounded'],
    package_size: ['250g', '500g', '750g', '1kg'],
    available_stock: 140,
    image: images.cubico_coffee
  },
  {
    id: 7,
    product_name: 'Toye Medium-Dark Roast Ground Bale Coffee',
    brand_name: 'Toye Coffee',
    price_usd: 18.5,
    price_birr: 970,
    
    roast_level: 'Medium-Dark',
    origin: 'Bale',
    processed_type: ['Grounded', 'Not Grounded'],
    package_size: ['250g', '500g', '750g'],
    available_stock: 160,
    image: images.toye_coffee
  },
  {
    id: 8,
    product_name: 'Wild Coffee  Dark Roast Blended sidamo Coffee',
    brand_name: 'Wild Coffee',
    price_usd: 20.0,
    price_birr: 1050,

    roast_level: 'Dark',
    origin: 'Sidama',
    processed_type: ['Grounded', 'Not Grounded'],
    package_size: ['750g', '1kg'],
    available_stock: 5,
    image: images.wild_coffee
  },
  {
    id: 9,
    product_name: 'Marley Coffee Medium Roast Grounded Yirga chaffee Coffee',
    brand_name: 'Marley Coffee',
    price_usd: 24.0,
    price_birr: 1550,

    roast_level: 'Medium',
    origin: 'Yirga chaffe',
    processed_type: ['Grounded', 'Not Grounded'],
    package_size: ['250g', '500g', '750g'],
    available_stock: 90,
    image: images.marley_coffee
  },
  {
    id: 10,
    product_name: 'Marley Coffee Medium Roast Blended  Coffee',
    brand_name: 'Marley Coffee',
    price_usd: 24.0,
    price_birr: 1550,
    roast_level: 'Medium',
    origin: 'Yirga chaffe',
    processed_type: ['Grounded', 'Not Grounded'],
    package_size: ['1kg'],
    available_stock: 90,
    image: images.grumpy_coffee
  },
  {
    id: 11,
    product_name: 'Adiss Ababba Coffee Medium Roast',
    brand_name: 'Adiss Ababba  Coffee',
    price_usd: 24.0,
    price_birr: 1550,

    roast_level: 'Medium',
    origin: 'Yirga chaffe',
    processed_type: ['Grounded', 'Not Grounded'],
    package_size: ['250g', '750g', '1kg'],
    available_stock: 90,
    image: images.adiss_ababa_coffee
  }
]
const Orders = [
  {
    id: 0,
    first_name: 'Surafel ',
    last_name: 'Melaku',
    email: 'user@gmail.com',
    phone_number: '+2519202020',
    city: 'Adiss Ababa',
    address: 'Piyassa',
    delivery_instruction:
      'Please bring to piyassa some building at the second floor, shop number 120',
    discount_code: '456825',
    item: 'Hadero Coffee Light Roast',
    quantity: 2,
    price: '1200 ETB',
    package_size: '250g',
    status: false,
    date: '2024-07-01'
  },
  {
    id: 1,
    first_name: 'Kaleab',
    last_name: 'Alemu',
    email: 'user@gmail.com',
    phone_number: '+251914719859',
    city: 'Adiss Ababa',
    address: 'Piyassa',
    delivery_instruction:
      'Please bring to piyassa some building at the second floor, shop number 120',
    discount_code: '128596',
    item: 'Hadero Coffee Light Roast ',
    quantity: 3,
    price: '100 ETB',
    package_size: '500g',
    status: false,
    date: '2024-07-05'
  },
  {
    id: 2,
    first_name: 'John',
    last_name: 'Doe',
    email: 'user@gmail.com',
    phone_number: '+251914719859',
    city: 'Adiss Ababa',
    address: 'Piyassa',
    delivery_instruction: '',
    discount_code: '456825',
    item: 'Hadero Coffee Light Roast',
    quantity: 1,
    price: '900 ETB',
    package_size: '750g',
    status: 'pending',
    date: '2024-07-10'
  },
  {
    id: 3,
    first_name: 'Julia',
    last_name: 'Fernandez',
    email: 'user@gmail.com',
    phone_number: '+251914719859',
    city: 'Adiss Ababa',
    address: 'Piyassa',
    delivery_instruction:
      'Please bring to piyassa some building at the second floor, shop number 120',
    discount_code: '206825',
    item: 'Hadero Coffee Light Roast',
    quantity: 4,
    price: '$50',
    package_size: '750g',
    status: "pending",
    date: '2024-06-20'
  },
  {
    id: 4,
    first_name: 'Alazar',
    last_name: 'Dawit',
    email: 'user@gmail.com',
    phone_number: '+251914719859',
    city: 'Adiss Ababa',
    address: 'Piyassa',
    delivery_instruction:
      'Please bring to piyassa some building at the second floor, shop number 120',
    discount_code: '856825',
    item: 'Hadero Coffee Light Roast ',
    quantity: 2,
    price: '1822 ETB',
    package_size: '1kg',
    status: true,
    date: '2024-07-01'
  }
]
const Brands = [
  {
    id: 1,
    origin: '',
    brand_name: 'Kedemit Coffee',
    detail:
      ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. At odi necessitatibus unde officia et ipsum repellendus quibusdam atque sapiente, tenetur voluptatem numquam ipsam maxime nostrum. Tenetur illo quidem cupiditate sint.',
    image: images.toye_logo,
    product_image: images.coffee_product,
    brand_image: images.kedemit_coffee_image,
    bgColor: '#ad926b'
  },
  {
    id: 2,
    origin: 'Sidama',
    brand_name: 'Hadero Coffee',
    detail:
      ' Lour illo qrem ipsum dolor sit amet, consectetur adipisicing elit. At odi necessitatibus unde officia et ipsum repellendus quibusdam atque sapiente, tenetur voluptatem numquam ipsam maxime nostrum. Tenetuidem cupiditate sint.',
    image: images.hadero_coffee_logo,
    product_image: images.hadero_product,
    brand_image: images.hadero_coffee_2,
    bgColor: '#b97a57'
  },
  {
    id: 3,
    origin: 'Harrar',
    brand_name: "Kaldi's Coffee",
    detail:
      ' Tenetuidem cuLour ill unde officia et ipsum repellendus quibusdam atque sapiente, tenetur voluptatem numquam ipsam maxime noso qrem ipsum t amet, consectetur adipisicing elit. At odi necessitatibustrum. pididolor sitate sint.',
    image: images.kaldis_logo,
    product_image: images.coffee_product,
    brand_image: images.kaldis_banner,
    bgColor: '#c9b08b'
  },
  {
    id: 4,
    origin: 'Yirga chaffe',
    brand_name: 'Tomoca Coffee',
    detail:
      ' sinur illo qrem Lo odi necessde cupiditat repellendus quibusdam atque sapiente, tenetur voluptatem numquam ipsam maxime nostrum. itatibus unTenetuie ipsum dolor sit amet officia et ipsumdem, consectetur adipisicing elit. Att.',
    product_image: images.coffee_product,
    image: images.tomoca_logo,
    brand_image: images.tomoca_banner,
    bgColor: '#9e8b45'
  }
]

const Users = [
  {
    id: 0,
    first_name: 'Surafel',
    last_name: 'Dawit',
    email: 'user@gmail.com',
    phone_number: '+251914719859',
    city: 'Adiss Ababa',
    country: 'Ethiopia',
    total_orders: 10,
    blacklisted: false
  },
  {
    id: 1,
    first_name: 'Alazar',
    last_name: 'Dawit',
    email: 'user@gmail.com',
    phone_number: '+251914719859',
    city: 'Adiss Ababa',
    country: 'Ethiopia',
    total_orders: 2,
    blacklisted: false
  },
  {
    id: 2,
    first_name: 'John',
    last_name: 'Doe',
    email: 'user@gmail.com',
    phone_number: '',
    city: 'Sydney',
    country: 'Australia',
    total_orders: 1,
    blacklisted: false
  }
]

const Discount = [
  {
    id: 0,
    code: '458785',
    expiry_date: '12-10-2024',
    discount_rate: 10,
    active: true
  },
  {
    id: 1,
    code: '205887',
    expiry_date: '10-06-2024',
    discount_rate: 5,
    active: true
  },
  {
    id: 2,
    code: '457875',
    expiry_date: '12-10-2024',
    discount_rate: 15,
    active: false
  }
]

const UserOrder = [
  {
    id: 0,
    item: 'Hadero Coffee Light Roast organic Arabica Ground Coffee , Arabica',
    quantity: 2,
    package_size: '1kg',
    price: '1700 ETB',
    address: 'Bole',
    delivery_instruction:
      'Please bring to piyassa some building at the second floor, shop number 120',
    discount_code: '856825',
    deliverd: true,
    date: '2024-07-01'
  },
  {
    id: 1,
    item: 'Hadero Coffee Light Roast organic Arabica Ground Coffee , Arabica',
    quantity: 2,
    package_size: '750g',
    price: '1822 ETB',
    address: 'Piyassa',
    delivery_instruction:
      'Please bring to piyassa some building at the second floor, shop number 120',
    discount_code: '856825',
    deliverd: false,
    date: '2024-17-01'
  },
  {
    id: 2,
    item: 'Hadero Coffee Light Roast organic Arabica Ground Coffee , Arabica',
    quantity: 2,
    package_size: '250g',
    price: '1822 ETB',
    address: 'Piyassa',
    delivery_instruction:
      'Please bring to piyassa some building at the second floor, shop number 120',
    discount_code: '856825',
    deliverd: false,
    date: '2024-07-18'
  }
]

const Images = [
  {
    image: images.coffee_tree
  },
  {
    image: images.coffee_beans
  },
  {
    image: images.coffee_tree
  },
  {
    image: images.coffee_roast
  },
  {
    image: images.coffee_beans
  },
  {
    image: images.coffee_roast
  }
]
const Testimonals = [
  {
    image: images.coffee_beans,
    saying:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, aut provident. Earum debitis consequuntur amet numquam adipisci corporis, voluptatem quibusdam enim excepturi, porro consequatur voluptatum nisi? Consequuntur in eius officia',
    name: 'Sara Tayler'
  },
  {
    image: images.coffee_beans,
    saying:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, aut provident. Earum debitis consequuntur amet numquam adipisci corporis, voluptatem quibusdam enim excepturi, porro consequatur voluptatum nisi? Consequuntur in eius officia',
    name: 'Stephen Farshing'
  },
  {
    image: images.coffee_beans,
    saying:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, aut provident. Earum debitis consequuntur amet numquam adipisci corporis, voluptatem quibusdam enim excepturi, porro consequatur voluptatum nisi? Consequuntur in eius officia',
    name: 'Lexi Tayler'
  },
  {
    image: images.coffee_beans,
    saying:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, aut provident. Earum debitis consequuntur amet numquam adipisci corporis, voluptatem quibusdam enim excepturi, porro consequatur voluptatum nisi? Consequuntur in eius officia',
    name: 'Maya Tayler'
  }
]
export default {
  Store,
  Brands,
  Orders,
  Users,
  Discount,
  UserOrder,
  Testimonals,
  Images
}
