import React, { useState, useEffect } from 'react'
import { Button } from '@material-tailwind/react'
import InputField from '../Components/InputField'
const ForgetPassword = () => {
  const [email, setEmail] = useState('')
  const [emailError, setEmaiError] = useState('')
  const handleForgetPassword = e => {
    e.preventDefault()
    if (validateEmail() === false) {
      return
    } else {
      console.log('login user')
    }
  }
  const validateEmail = () => {
    const failEmail = !email || email.length === 0
    if (failEmail) {
      setEmaiError('Email is required!')
    } else {
      setEmaiError('')
    }

    if (failEmail) {
      return false
    }
  }
  useEffect(() => {
    document.title = 'Forgot Password | Abol Coffee'
  })
  return (
    <div className='h-full w-full flex flex-col  px-[20px] items-center justify-start py-[50px]'>
      <div className='flex flex-col w-full sm:w-[400px]  md:w-[450px] gap-10'>
        <h1 className='text-[30px] text-center '>Reset Password</h1>

        <div className='flex flex-col w-full '>
          <form className='flex flex-col gap-4 '>
            <div className='w-full flex flex-col gap-[5px]'>
              <InputField
                required={true}
                type='email'
                name='email'
                error={emailError && emailError}
                placeholder='Email'
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <Button
              onClick={e => handleForgetPassword(e)}
              className='p-3 mt-5 bg-accent text-primary border-[2px]  font-serif text-[17px] capitalize w-full hover:bg-transparent hover:text-secondary hover:border-secondary rounded-none'
            >
              RESET PASSWORD
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword
