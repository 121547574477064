import React, { useState } from 'react'
import { data } from '../../Constants'
import SendEmailModal from '../../Components/Admin/SendEmailModal'
import useGlobal from '../../Core/global'
import { BiDotsVertical } from 'react-icons/bi'
import utils from '../../Core/utils'
import Pagination from '../../Components/Pagination'
const User = () => {
  const { openModal } = useGlobal()
  const handleOpenModal = itemId => {
    openModal(itemId)
  }

  const [users] = useState(data.Users)
  const [filterQuery, setFilterQuery] = useState('')

  const handleInputChange = e => {
    setFilterQuery(e.target.value)
  }

  const filteredUsers = users.filter(user => {
    const { first_name, last_name, email, phone_number } = user
    const query = filterQuery.toLowerCase()
    return (
      first_name.toLowerCase().includes(query) ||
      last_name.toLowerCase().includes(query) ||
      email.toLowerCase().includes(query) ||
      phone_number.toLowerCase().includes(query)
    )
  })

  // Pagination
  const [currentPage, setCurrentPage] = useState(0)
  const productsPerPage = 10 // This can be changed to set the items to display per  page

  const pageCount = Math.ceil(filteredUsers.length / productsPerPage)

  const handlePageChange = selectedPage => {
    setCurrentPage(selectedPage)
  }

  const usersData = filteredUsers.slice(
    currentPage * productsPerPage,
    (currentPage + 1) * productsPerPage
  )
  return (
    <div className='h-full w-full flex flex-col px-2 md:px-4 py-5 bg-white overflow-x-scroll'>
      <div>
        <h1 className='text-[30px] font-bold'>Users #{data.Users.length}</h1>

        <div className='flex gap-4 items-center justify-between'>
          <div className='flex items-center gap-4'>
            <input
              type='text'
              value={filterQuery}
              onChange={handleInputChange}
              placeholder='Nme, Email, Phone '
              className='w-[350px]'
            />
          </div>
          <div className='flex items-center gap-4'>
            <button
              type='button'
              onClick={() => utils.exportToCSV(data.Users, 'Users')}
              className='p-2 e w-[150px]  hover:bg-secondary/90  px-4 py-3 bg-secondary text-white'
            >
              Export CSV
            </button>
          </div>
        </div>

        <table className='w-full mt-3 ' id='users-table'>
          <thead className=''>
            <tr>
              <td>No</td>
              <td>Full Name</td>
              <td>Email</td>
              <td>Phone NO</td>
              <td>Country</td>
              <td>Total Orders</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {usersData.map((user, key) => (
              <tr key={key}>
                <td className='max-w-[20px]'>{key + 1}</td>
                <td>
                  {user.first_name} {user.last_name}
                </td>
                <td>{user.email}</td>
                <td>{user.phone_number}</td>
                <td>{user.country}</td>

                <td>{user.total_orders}</td>
                <td className='relative'>
                  <div className='relative store-action-menu  w-max'>
                    <BiDotsVertical size={20} className='-z-5' />{' '}
                    <ul className='absolute top-0 -right-[20px] z-[10] bg-white rounded-md shadow-md p-2 min-w-[150px] store-menus'>
                      <li className='flex text-dark px-2 py-1 rounded-lg hover:bg-dark/10 w-full'>
                        <button
                          onClick={() => handleOpenModal(key)}
                          className={'w-full'}
                        >
                          Send Email
                        </button>
                      </li>
                      <li className='flex text-dark px-2 py-1 rounded-lg hover:bg-dark/10 w-full'>
                        <button className={'w-full'}>Blacklist user</button>
                      </li>
                      <li className='flex text-dark px-2 py-1 rounded-lg hover:bg-dark/10 w-full'>
                        <button className={'w-full'}>Delete user</button>
                      </li>
                    </ul>
                  </div>
                </td>
                <td>
                  <SendEmailModal itemId={key} onClose={() => {}} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
      </div>
    </div>
  )
}

export default User

//#6b4423
