import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import useGlobal from '../Core/global'
import { useNavigate } from 'react-router-dom'
import { Button } from '@material-tailwind/react'
import InputField from '../Components/InputField'
const Login = () => {
  const navigate = useNavigate()
  const login = useGlobal(state => state.login)
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: ''
  })
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmaiError] = useState('')

  const handleChange = e => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    })
  }
  const handleLogin = e => {
    e.preventDefault()
    if (validateLogIn() === false) {
      return
    } else {
      if (
        userInfo.email === 'user@gmail.com' ||
        userInfo.email === 'admin@gmail.com'
      ) {
        if (userInfo.password === '123456') {
          console.log(userInfo.password)
          login(userInfo)
          navigate('/')
        } else {
          setPasswordError('password not found')
        }
      } else {
        setEmaiError('Email not found!')
        return
      }
    }
  }

  const validateLogIn = () => {
    let failEmail = !userInfo.email || userInfo.email.length === 0

    if (failEmail) {
      setEmaiError('Email is required!')
    } else {
      setEmaiError('')
    }

    let failPassword = !userInfo.password
    if (failPassword) {
      setPasswordError('Password is required!')
    } else if (userInfo.password.length < 6) {
      failPassword = true
      setPasswordError('Password is too short!')
    } else {
      setPasswordError('')
    }

    if (failPassword || failEmail) {
      return false
    }
  }
  const handleLoginGoogle = () => {}

  useEffect(() => {
    document.title = 'Log In | Abol Coffee'
  })
  return (
    <>
      <div className='h-full w-full flex flex-col items-center justify-start px-[20px] py-[50px]'>
        <div className='flex flex-col w-full sm:w-[400px]  md:w-[450px]'>
          <div className='space-y-5 mb-5'>
            <h1 className=' text-center text-[30px]'>Login</h1>
            <p className='text-center'>
              Enter your email and password to login
            </p>
          </div>
          <form className='flex flex-col gap-5'>
            <div className='w-full flex flex-col'>
              <InputField
                type='email'
                name='email'
                placeholder='Email'
                onChange={e => handleChange(e)}
                error={emailError && emailError}
                required={true}
              />
            </div>
            <div className='w-full flex flex-col '>
              <InputField
                type='password'
                name='password'
                onChange={e => handleChange(e)}
                placeholder='Password'
                error={passwordError && passwordError}
                ispass={true}
                required={true}
              />
            </div>

            <Button
              onClick={e => handleLogin(e)}
              className='p-3 mt-5 bg-accent text-primary border-[2px]  font-serif text-[17px] capitalize w-full hover:bg-transparent hover:text-secondary hover:border-secondary rounded-none'
            >
              LOGIN
            </Button>
            <div>
              <p>
                Don't have an account?{' '}
                <Link
                  to='/register'
                  className='text-[15px] text-secondary font-semibold hover:text-olivegreen/90 hover:underline '
                >
                  Register now!
                </Link>
              </p>

              <Link
                to='/reset-password'
                className='text-[15px] text-secondary font-semibold hover:text-olivegreen/90 hover:underline '
              >
                Forgot Password?
              </Link>
            </div>
          </form>
          <div className='or-container mt-5'>
            <span className='or-text'>or</span>
          </div>
          <div className='flex items-center gap-5 w-full justify-center mt-[25px]'>
            <Button
              variant='outlined'
              action={handleLoginGoogle}
              className='w-full rounded-none capitalize font-serif text-[16px]'
            >
              Login with Google
            </Button>
          </div>
          <p className='text-[15px] mt-[10px]'>
            admin@gmail.com &nbsp;&nbsp;&nbsp; password:123456 <br />
            (for testing state managment)
          </p>
        </div>
      </div>
    </>
  )
}

export default Login
