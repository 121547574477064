import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from '../Components/NavBar'
import Footer from '../Components/Footer'
import useGlobal from '../Core/global'
import { useLocation } from 'react-router-dom'
const Layout = () => {
  const location = useLocation()
  const isHomePage = location.pathname === '/'
  const admin = useGlobal(state => state.admin)
  useEffect(() => {
    const handleScroll = () => {
      if (isHomePage) {
        document.querySelector('.outlet-container').classList.remove('sticky')
      }
    }
    handleScroll()
  }, [])
  return (
    <div className=''>
      <div className='nav-layout'>
        <NavBar />
      </div>
      <div
        style={isHomePage ? { padding: 0 } : {}}
        className='outlet-container h-auto'
      >
        <Outlet />
      </div>
      {!admin && (
        <div>
          <Footer />
        </div>
      )}
    </div>
  )
}

export default Layout
