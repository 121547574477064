import React, { useEffect, useState, useRef } from 'react'
import CartItem from '../Components/CartItem'
import { useNavigate } from 'react-router-dom'
import useGlobal from '../Core/global'
import { Button } from '@material-tailwind/react'
const Cart = () => {
  const navigate = useNavigate()
  const cartItems = useGlobal(state => state.cartItems)
  const [totalItems, setTotalItems] = useState('')
  const country = useGlobal(state => state.country)
  const [totalPrice, setTotalPrice] = useState('')
  const handlePlaceOrder = () => {
    navigate('/place-order')
  }
  const btnRef = useRef(null)
  useEffect(() => {
    document.title = 'Cart | Abol Coffee'
  })
  useEffect(() => {
    let totalItems = 0
    cartItems.forEach(item => (totalItems += item.quantity))
    setTotalItems(totalItems)

    let totalPrice = 0
    cartItems.forEach(
      item =>
        (totalPrice +=
          country === 'ET'
            ? item.price_birr * item.quantity
            : item.price_usd * item.quantity)
    )
    setTotalPrice(totalPrice)

    if (totalItems === 0 || cartItems.length === 0) {
      btnRef.current.disabled = true
      btnRef.current.style.cursor = 'not-allowed'
    } else {
      btnRef.current.disabled = false
      btnRef.current.style.cursor = 'pointer'
    }
  }, [cartItems])
  return (
    <div className='  min-h-[80vh] w-full'>
      <div className='m-auto h-auto max-w-[1440px] p-[20px] md:p-[30px] ld:p-[40px] flex flex-col items start '>
        <div className='w-full md:w-3/4 pr-[30px] flex items-center justify-between'>
          <h1 className='text-dark text-[25px] md:text-[3.5rem] capitalize mb-[20px] py-[20px]'>
            Your Cart
          </h1>
          <Button
            onClick={() => navigate('/shop')}
            variant='outlined'
            className='p-3 border-secondary border-[2px] bg-transparent text-secondary font-serif text-[17px] capitalize w-[200px] hover:bg-secondary/10 rounded-none text-center'
          >
            Continue Shopping
          </Button>
        </div>

        <div className='flex items-start justify-between gap-10 flex-col-reverse lg:flex-row'>
          <div className='flex flex-col gap-[10px] items start w-full lg:w-3/4'>
            {cartItems.length > 0 ? (
              <>
                <table className='cart-table min-w-full bg-transparent border-collapse block md:table'>
                  <thead className='block md:table-header-group'>
                    <tr className='border border-gray-200 block md:table-row bg-secondary/5'>
                      <td className='p-2'>Product</td>
                      <td className='p-2'>Quantity</td>
                      <td className='p-2'>Total</td>
                    </tr>
                  </thead>
                  <tbody className='block md:table-row-group'>
                    {cartItems.map((item, id) => (
                      <CartItem key={id} item={item} />
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <div className='w-full h-[250px] flex flex-col items-center justify-center'>
                <h1 className='text-[40px]'> No items in cart</h1>
                <Button
                  variant='outlined'
                  onClick={() => navigate('/shop')}
                  className='p-3 border-secondary border-[2px] bg-transparent text-secondary font-serif text-[17px] capitalize w-[150px] hover:bg-secondary/10 rounded-none text-center'
                >
                  See Products
                </Button>
              </div>
            )}
          </div>
          <div className='w-full lg:w-1/4 bg-primary p-2 h-auto  shadow-md'>
            <h1 className='text-dark text-[25px] capitalize mb-[10px]'>
              Cart Summary
            </h1>
            <div className='flex items-center justify-between gap-10'>
              <h1 className='text-dark text-[15px] capitalize'>Total Items</h1>
              <h1 className='text-dark text-[15px] capitalize'>{totalItems}</h1>
            </div>
            <div className='flex items-center justify-between gap-10'>
              <h1 className='text-dark text-[18px] capitalize'>Total Price</h1>
              <h1 className='text-dark text-[25px] capitalize font-semibold'>
                {country === 'ET' ? totalPrice + 'ETB' : '$' + totalPrice}
              </h1>
            </div>

            <Button
              type='button'
              ref={btnRef}
              onClick={() => handlePlaceOrder()}
              className='p-3 mt-5 bg-accent text-primary border-[2px]  font-serif text-[17px] capitalize w-full hover:bg-transparent hover:text-secondary hover:border-secondary rounded-none'
            >
              Proceed to Checkout
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cart
