import React, { useState } from 'react'
import { data } from '../../Constants'
import { BiDotsVertical } from 'react-icons/bi'
import GenerateSiscountCodeModal from '../../Components/Admin/GenerateSiscountCodeModal'
import Pagination from '../../Components/Pagination'
const Discount = () => {
  const [showModal, setShowModal] = useState(false)

  const [discount] = useState(data.Discount) // Load initial users data
  const [filterQuery, setFilterQuery] = useState('') // State to hold filter query

  // Handle input change and update filter query
  const handleInputChange = e => {
    setFilterQuery(e.target.value)
  }

  // Filter users based on the filter query across multiple fields
  const filteredDiscount = discount.filter(discount => {
    const query = filterQuery.toLowerCase()
    return discount.code.includes(query)
  })

  // discount page Pagination
  const [currentPage, setCurrentPage] = useState(0)
  const productsPerPage = 10 // This can be changed to set the items to display per  page

  const pageCount = Math.ceil(filteredDiscount.length / productsPerPage)

  const handlePageChange = selectedPage => {
    setCurrentPage(selectedPage)
  }

  const discountData = filteredDiscount.slice(
    currentPage * productsPerPage,
    (currentPage + 1) * productsPerPage
  )
  return (
    <div className='h-full w-full flex flex-col px-2 md:px-4 py-5 bg-white overflow-x-scroll'>
      <div>
        <h1 className='text-[30px] font-bold'>
          Discount #{data.Discount.length}
        </h1>

        <div className='flex gap-4 items-center justify-between'>
          <div className='flex items-center gap-4'>
            <input
              type='text'
              value={filterQuery}
              onChange={handleInputChange}
              placeholder='Code '
              className='w-[350px]'
            />
          </div>
          <div className='flex items-center gap-4'>
            <button
              type='button'
              onClick={() => setShowModal(!showModal)}
              className='p-2  w-[150px]  hover:bg-secondary/90  px-4 py-3 bg-secondary text-white'
            >
              Generate Code
            </button>
          </div>
        </div>

        <table className='w-full mt-3   '>
          <thead className=''>
            <tr>
              <td>No</td>
              <td>Code</td>
              <td>Discount Rate</td>
              <td>Expiry Date</td>
              <td>Status</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {discountData.map((discount, key) => (
              <tr key={key}>
                <td className='max-w-[20px]'>{key + 1}</td>
                <td>{discount.code}</td>
                <td>{discount.discount_rate} %</td>

                <td>{discount.expiry_date}</td>
                <td className='w-[120px]'>
                  {discount.active ? (
                    <p className='text-green-800  border-[1px] border-green-500 bg-green-400/30 rounded-md p-1 text-[13px]  text-center font-bold '>
                      Active
                    </p>
                  ) : (
                    <p className='text-red-800 border-[1px] border-red-400 bg-red-400/30 rounded-md text-[13px]  p-1  text-center font-bold '>
                      Not Active
                    </p>
                  )}
                </td>

                <td className='relative store-action-menu w-[50px]'>
                  {' '}
                  <BiDotsVertical size={20} className='-z-5' />{' '}
                  <ul className='absolute top-0 -right-[20px] z-[10] bg-white rounded-md shadow-md p-2 min-w-[150px] store-menus'>
                    <li className='flex text-dark px-2 py-1 rounded-lg hover:bg-dark/10 w-full'>
                      <button className={'w-full'}>Delete Code</button>
                    </li>
                    <li className='flex text-dark px-2 py-1 rounded-lg hover:bg-dark/10 w-full'>
                      {discount.active ? (
                        <button className={'w-full'}>Deactivate</button>
                      ) : (
                        <button className={'w-full'}>Activate</button>
                      )}
                    </li>
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
        <GenerateSiscountCodeModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </div>
    </div>
  )
}

export default Discount
