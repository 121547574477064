import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Menus from './Menus'

const AdminLayout = () => {
  useEffect(() => {
    document.title = 'Admin | Abol Coffee'
  })
  const [showMenu, setShowMenu] = useState(false)
  return (
    <div className=''>
      <div className='flex w-full h-full bg-white '>
        <div className='menus'>
          <Menus showmenu={showMenu} setShowMenu={setShowMenu} />
        </div>
        <div className='outlet min-h-[90vh] overflow-y-scroll w-full'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default AdminLayout
