import React from 'react'
import { NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'

const NavBarLink = ({ to, children }) => {
  return (
    <NavLink replace to={to} className='relative px-2 py-2'>
      {({ isActive }) => (
        <>
          <span className='relative z-3 text-secondary  text-[17px]'>
            {children}
          </span>
          <motion.div
            className='absolute bottom-0 left-0 right-0 h-[2px] bg-secondary font-bold '
            initial={{ width: 0 }}
            animate={{ width: isActive ? '100%' : '0%' }}
            transition={{ duration: 0.4 }}
          />
        </>
      )}
    </NavLink>
  )
}

export default NavBarLink
