import React, { useEffect } from 'react'
import { data, images } from '../Constants'
import { useNavigate } from 'react-router-dom'
import BrandsSlider from '../Components/BrandsSlider'
import { Button } from '@material-tailwind/react'
import ProductsSlider from '../Components/ProductsSlider'
import { motion } from 'framer-motion'
const Home = () => {
  const navigate = useNavigate()
  useEffect(() => {
    document.title = 'Home | Abol Coffee'
  })
  return (
    <div>
      {/* Header */}
      <div className='w-full h-screen bg-primary flex justify-center items-center'>
        <div className='flex flex-col  bg-black w-full h-full relative justify-center items-center'>
          <div className='w-full h-full '>
            <motion.img
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 0.6 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.2 }}
              src={images.coffee_baner}
            />
          </div>
          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 px-[20px] -translate-y-1/2 flex flex-col items-center w-full ] md:w-[80%] gap-4'>
            <motion.h1
              initial={{ y: 150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.2 }}
              className='  md:text-[70px]  font-[700] text-center text-primary'
            >
              Fuel Your Passion: Premium Coffees for Every Palate
            </motion.h1>
            <motion.h2
              initial={{ y: 150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.4 }}
              className='text-primary text-center'
            >
              Aromatic coffee deliverd to your house.
            </motion.h2>
            <motion.div
              initial={{ y: 150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.6 }}
              className='flex flex-col items-center gap-5 md:gap-10 sm:flex-row '
            >
              <Button
                onClick={() => navigate('/shop')}
                className='p-3 bg-primary font-serif text-[17px] text-secondary capitalize w-[200px]  rounded-none'
              >
                Order Now
              </Button>
              <Button
                variant='outlined'
                onClick={() => navigate('/brands')}
                className='p-3 bg-none border-[2px] text-primary border-primary outline-none font-serif text-[17px] capitalize w-[200px] rounded-none'
              >
                Learn More
              </Button>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Products list section */}
      <div className='h-auto w-full bg-primary p-[20px] md:p-[40px] lg:p-[50px]'>
        <motion.h1
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.4 }}
          className='text-center text-[30px] md:text-[3.4rem] capitalize mb-[50px] line-after-brown line-center'
        >
          Coffee Products
        </motion.h1>
        <ProductsSlider products={data.Store} />

        <motion.div className='flex justify-center mt-[50px]  w-full'>
          <Button
            initial={{ y: 150, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.6 }}
            onClick={() => navigate('/shop')}
            variant='outlined'
            className='p-3 border-secondary border-[2px]  bg-transparent text-secondary font-serif text-[17px] capitalize w-[200px] hover:bg-secondary hover:text-primary rounded-none'
          >
            View More
          </Button>
        </motion.div>
      </div>

      {/* Something about abol like: what abol does and how abol is helping his cutomerg get the best coffee */}
      <div className=' h-auto w-full '>
        <div className='m-auto h-auto max-w-[1440px] items-center py-[50px] justify-between px-[20px] md:px-[30px] lg:px-[50px] gap-[80px] lex-col md:flex '>
          <div className='flex flex-col gap-5 items-start justify-start p-0 md:py-[50px] w-full md:w-2/3'>
            <motion.h1
              initial={{ y: 150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.2 }}
              className='text-center md:text-start w-full text-dark mb-[20px] text-secondar line-after-brown'
            >
              Taste the Difference
            </motion.h1>
            <motion.p
              initial={{ y: 150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.3 }}
              className='text-center md:text-start'
            >
              Abol Coffee is your premier online destination for authentic
              Ethiopian coffee. We curate and offer a diverse selection of
              packaged coffees from various local Ethiopian brands, bringing the
              finest beans from Ethiopia's renowned growing regions directly to
              your doorstep.
            </motion.p>
            <motion.p
              className='text-center md:text-start'
              initial={{ y: 150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.4 }}
            >
              Discover the unique flavors and stories behind each brand as we
              connect you with Ethiopia's rich coffee culture.
            </motion.p>
            <motion.div
              initial={{ y: 150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.5 }}
              className='w-full flex justify-center md:justify-start'
            >
              <Button
                onClick={() => navigate('/shop')}
                className='p-3 bg-secondary text-primary border-[2px] shadow-none  font-serif text-[17px] capitalize w-[200px] hover:border-secondary hover:bg-transparent rounded-none hover:text-secondary'
              >
                Visit Store
              </Button>
            </motion.div>
          </div>
          <div className='w-full hidden md:flex md:w-1/2 h-[500px] justify-end  '>
            <motion.img
              initial={{ y: -150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.3 }}
              src={images.jbena_2}
              alt=''
              className='object-contain w-max'
            />
          </div>
        </div>
      </div>

      {/* Coffee Producst Slider */}
      <div className='h-auto w-full bg-primary'>
        <BrandsSlider />
      </div>

      {/* single Product */}
      <div className='h-auto w-full bg-four/20'>
        <div className='m-auto h-auto max-w-[1440px] items-center py-[50px] justify-between px-[20px] md:px-[30px] lg:px-[50px] gap-[80px] flex flex-col md:flex-row'>
          <div className='w-full hidden md:flex md:w-max h-[500px] justify-start'>
            <motion.img
              initial={{ y: -150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.3 }}
              src={images.delivery}
              alt=''
              className='object-contain '
            />
          </div>
          <div className='flex flex-col gap-5 items-start justify-start p-0 md:py-[50px] w-full md:w-2/3'>
            <motion.h1
              initial={{ y: 150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.2 }}
              className='text-center md:text-start w-full mb-[20px] text-secondary line-after-brown'
            >
              Right away at your door!
            </motion.h1>
            <motion.p
              initial={{ y: 150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.3 }}
              className='text-center md:text-start'
            >
              Abol Coffee is your premier online destination for authentic
              Ethiopian coffee. We curate and offer a diverse selection of
              packaged coffees from various local Ethiopian brands, bringing the
              finest beans from Ethiopia's renowned growing regions directly to
              your doorstep.
            </motion.p>
            <motion.p
              initial={{ y: 150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.4 }}
              className='text-center md:text-start'
            >
              Discover the unique flavors and stories behind each brand as we
              connect you with Ethiopia's rich coffee culture.
            </motion.p>
            <motion.div
              initial={{ y: 150, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.5 }}
              className='w-full flex justify-center md:justify-start'
            >
              <Button
                onClick={() => navigate('/shop')}
                className='p-3 bg-secondary text-primary border-[2px] shadow-none  font-serif text-[17px] capitalize w-[200px] hover:border-secondary hover:bg-transparent rounded-none hover:text-secondary'
              >
                Order Now
              </Button>
            </motion.div>
          </div>
        </div>
      </div>

      {/* where is the coffee coming from */}
      <div className='h-auto w-full '>
        <div className='m-auto h-auto max-w-[1440px] items-center justify-center py-[50px] px-[20px] md:px-[30px] lg:px-[50px] gap-[20px] flex-col md:flex '>
          <motion.div
            initial={{ y: 150, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.2 }}
            className='flex flex-col gap items-center'
          >
            <h1 className='mb-[20px] text-center line-after-brown line-center'>
              Where is the coffee coming from?
            </h1>
            <p className='text-center w-full md:w-2/3 text-cold'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Laudantium dicta numquam, reprehenderit quo, minima aut rem
              recusandae temporibus iure nulla corporis pariatur harum fuga quod
              vitae ea veniam asperiores repellendus.
            </p>
          </motion.div>

          <div className='flex gap-5  md:gap-[80px]  flex-col md:flex-row mt-10'>
            <div className='flex flex-col gap-5 items-start justify-start p-0 md:py-[50px] w-full md:w-1/2'>
              <motion.h2
                initial={{ y: 150, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.2 }}
                className='text-center md:text-start  text-[35px] text-secondary'
              >
                From the Highlands of Ethiopia
              </motion.h2>
              <motion.p
                initial={{ y: 150, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.3 }}
                className='text-center md:text-start'
              >
                Coffee in Ethiopia is harvested in coffee-growing regions
                include Sidamo (including Yirgacheffe), Harrar, Limu, and Jimma.
                Each region offers unique flavor profiles and characteristics
                due to variations in soil, elevation, and climate. Ethiopian
                coffee is celebrated worldwide for its distinctively fruity and
                floral notes, making it one of the most sought-after origins for
                specialty coffee enthusiasts.
              </motion.p>
              <motion.p
                initial={{ y: 150, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.4 }}
                className='text-center md:text-start'
              >
                Discover the unique flavors and stories behind each brand as we
                connect you with Ethiopia's rich coffee culture.
              </motion.p>
              <motion.div
                initial={{ y: 150, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.4 }}
                className='w-full flex justify-center md:justify-start'
              >
                <Button
                  onClick={() => navigate('/shop')}
                  className='p-3 bg-secondary text-primary border-[2px] shadow-none  font-serif text-[17px] capitalize w-[200px] hover:border-secondary hover:bg-transparent rounded-none hover:text-secondary'
                >
                  Learn More
                </Button>
              </motion.div>
            </div>
            <div className='w-full md:w-[500px] h-[500px]'>
              <motion.img
                initial={{ y: -150, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.3 }}
                src={images.coffee_harvest}
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
