import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import InputField from '../Components/InputField'
import { Input, Button } from '@material-tailwind/react'
const Register = () => {
  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: ''
  })
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmaiError] = useState('')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')

  const handleChange = e => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    })
  }
  const handleRegister = e => {
    e.preventDefault()
    if (validateRegister() === false) {
      return
    } else {
      console.log('Register User')
    }
  }

  const validateRegister = () => {
    let failFirstName = !userInfo.first_name
    if (failFirstName) {
      setFirstNameError('First Name is required!')
    } else {
      setFirstNameError('')
    }
    let failLastName = !userInfo.last_name
    if (failLastName) {
      setLastNameError('Last Name is required!')
    } else {
      setLastNameError('')
    }

    let failEmail = !userInfo.email || userInfo.email.length === 0
    if (failEmail) {
      setEmaiError('Email is required!')
    } else {
      setEmaiError('')
    }

    let failPassword = !userInfo.password
    if (failPassword) {
      setPasswordError('Password is required!')
    } else if (userInfo.password.length < 6) {
      failPassword = true
      setPasswordError('Password is too short!')
    } else {
      setPasswordError('')
    }

    if (failPassword || failEmail || failFirstName || failLastName) {
      return false
    }
  }

  useEffect(() => {
    document.title = 'Register | Abol Coffee'
  })
  return (
    <div className='h-full w-full flex-col flex  items-center justify-start px-[20px] py-[50px]'>
      <div className='flex flex-col ] w-full sm:w-[400px]  md:w-[450px]'>
        <div className='space-y-5 mb-5'>
          <h1 className=' text-center text-[30px]'>Register</h1>
          <p className='text-center'>Fill the form to register.</p>
        </div>
        <form className='flex flex-col gap-4 '>
          <div className='flex items-center gap-5 justify-between'>
            <div className='w-full flex flex-col gap-[5px]'>
              <InputField
                type='text'
                name='first_name'
                placeholder='First Name'
                onChange={e => handleChange(e)}
                error={firstNameError && firstNameError}
                required={true}
              />
            </div>
            <div className='w-full flex flex-col gap-[5px]'>
              <InputField
                type='text'
                name='last_name'
                placeholder='Last Name'
                onChange={e => handleChange(e)}
                error={lastNameError && lastNameError}
                required={true}
              />
            </div>
          </div>
          <div className='w-full flex flex-col gap-[5px]'>
            <InputField
              required={true}
              type='email'
              name='email'
              placeholder='Email'
              onChange={e => handleChange(e)}
              error={emailError && emailError}
            />
          </div>
          <div className='w-full flex flex-col gap-[5px]'>
            <InputField
              type='password'
              name='password'
              ispass={true}
              error={passwordError && passwordError}
              placeholder='Password'
              onChange={e => handleChange(e)}
            />
          </div>
          <div className='w-full flex justify-end'></div>
          <Button
            onClick={e => handleRegister(e)}
            className='p-3 mt-5 bg-accent text-primary border-[2px]  font-serif text-[17px] capitalize w-full hover:bg-transparent hover:text-secondary hover:border-secondary rounded-none'
          >
            REGISTER
          </Button>
          <p>
            Already have an account?{' '}
            <Link
              to='/login'
              className='text-[15px] text-secondary hover:text-secondary/90 hover:underline'
            >
              Login!
            </Link>
          </p>
        </form>
      </div>
    </div>
  )
}

export default Register
