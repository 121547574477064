import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai'
import { PiCaretLeftLight, PiCaretRightLight } from 'react-icons/pi'
import { IconContext } from 'react-icons'

const Pagination = ({ pageCount, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const handlePageClick = data => {
    const selectedPage = data.selected
    setCurrentPage(selectedPage)
    onPageChange(selectedPage)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentPage])

  return (
    <ReactPaginate
      previousLabel={
        <IconContext.Provider value={{ color: '#3a2618' }}>
          <PiCaretLeftLight size={35} />
        </IconContext.Provider>
      }
      nextLabel={
        <IconContext.Provider value={{ color: '#3a2618' }}>
          <PiCaretRightLight size={35} />
        </IconContext.Provider>
      }
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageClick}
      containerClassName={'pagination'}
      activeClassName={'activepage'}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      previousClassName={'page-item-prev'}
      nextClassName={'page-item-next'}
      previousLinkClassName={'page-link-prev'}
      nextLinkClassName={'page-link-next'}
      breakLinkClassName={'page-link'}
      disabledClassName={'disabledpage'}
    />
  )
}

export default Pagination
