// src/components/Toast.js
import { motion, AnimatePresence } from 'framer-motion'
import useGlobal from '../Core/global'

const Toast = () => {
  const { toasts, removeToast } = useGlobal()

  return (
    <div className='fixed top-[150px] bg-none right-4 flex flex-col space-y-4  z-50  shadow-2xl'>
      <AnimatePresence>
        {toasts.map(toast => (
          <motion.div
            key={toast.id}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            className={`${
              !toast.status ? 'bg-red-500/70' : 'bg-secondary'
            }  text-white text-[18px] font-bold  px-7 py-4 rounded `}
            onClick={() => removeToast(toast.id)}
          >
            {toast.message}
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  )
}

export default Toast
