import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@material-tailwind/react'
const NotFound = () => {
  const navigate = useNavigate()
  useEffect(() => {
    document.title = 'Not Found | Abol Coffee'
  })
  return (
    <div className='flex flex-col items-center justify-start min-h-screen gap-3'>
      <h1 className='text-[150px] md:text-[250px] font-bold items-end'>404</h1>
      <h1>Page Not Found !</h1>

      <Button
        onClick={() => navigate('/')}
        className='bg-secondary p-3 capitalize w-[180px]  mt-[25px]'
      >
        Back to Home
      </Button>
    </div>
  )
}

export default NotFound
