import React, { useEffect, useRef } from 'react'
import { PiXLight } from 'react-icons/pi'
import { useCountries } from 'use-react-countries'
const AddPriceModal = ({
  setShowAddPriceModal,
  showAddPriceModal,
  packageSizes
}) => {
  const { countries } = useCountries()
  const modalRef = useRef()
  useEffect(() => {
    const handleOutsideClick = event => {
      // Close modal if click is outside of modalRef
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowAddPriceModal(false)
      }
    }

    // Add event listener when component mounts
    document.addEventListener('mousedown', handleOutsideClick)

    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  const handleChange = () => {}
  return (
    <div
      className={`${
        showAddPriceModal ? 'flex' : 'hidden'
      } w-full bg-black/20 h-screen fixed z-[10] top-0 left-0  items-center  justify-center`}
    >
      <div
        className='bg-white p-4 h-auto w-[400px] rounded-lg relative '
        ref={modalRef}
      >
        <div className='absolute right-[10px]'>
          <PiXLight
            size={30}
            className='cursor-pointer p-1 hover:bg-secondary/10 rounded-full'
            onClick={() => setShowAddPriceModal(!showAddPriceModal)}
          />
        </div>
        <h1 className='text-[25px]'>Add Price</h1>
        <div>
          <form className='flex flex-col gap-2 w-full '>
            <div className='w-full flex flex-col gap-[3px]'>
              <p className='required text-dark'>Select Country</p>
              <select name='country' id='country'>
                <option value=''>Select Country</option>
                {countries.map(({ name, flags }) => (
                  <option
                    key={name}
                    value={name}
                    className='flex items-center gap-2'
                  >
                    <img
                      src={flags.svg}
                      alt={name}
                      className='h-5 w-5 rounded-full object-cover'
                    />
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className='w-full flex flex-col gap-[3px]'>
              <p className='required text-dark'>Select Package Size</p>
              <select name='country' id='country'>
                <option value=''>Select Package Size</option>
                {packageSizes.map((packageSize, key) => (
                  <option
                    key={key}
                    value={packageSize}
                    className='flex items-center gap-2'
                  >
                    {packageSize}
                  </option>
                ))}
              </select>
            </div>

            <div className='flex items-center gap-5'>
              <div className='w-full flex flex-col gap-[3px]'>
                <p className='required text-dark'>Price</p>
                <input
                  type='number'
                  name='price'
                  placeholder='set Price'
                  onChange={e => handleChange(e)}
                />
              </div>
              <div className=' w-full flex flex-col gap-[3px]'>
                <p className='required text-dark'>Currency</p>
                <select name='country' id='country'>
                  <option value=''>Select Currency</option>
                  <option value='ETB'>
                    <PiXLight />
                    ETB
                  </option>
                  <option value='USD'>USD</option>
                </select>
              </div>
            </div>
            <button
              type='button'
              className='p-2 e w-[150px] hover:bg-secondary/90  px-4 py-2 bg-secondary text-white mt-[20px]'
            >
              Add Price
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddPriceModal
