import React, { useEffect } from 'react'
import { data } from '../Constants'
import Brand from '../Components/Brand'
const Brands = () => {
  useEffect(() => {
    document.title = 'Brands | Abol Coffee'
  })
  return (
    <div className='  h-auto w-full'>
      <div className='m-auto h-auto max-w-[1440px] p-[20px] md:p-[30px] lg:p-[50px] '>
        <h1 className='text-center  md:text-left line-after-brown mb-5'>
          Coffee Brands
        </h1>

        <div className='grid grid-cols-1 gap-5'>
          {data.Brands.map((brand, key) => (
            <Brand key={key} brand={brand} rep={key} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Brands
