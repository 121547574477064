import React, { useEffect, useRef } from 'react'
import { PiXLight } from 'react-icons/pi'
const GenerateSiscountCodeModal = ({ showModal, setShowModal }) => {
  const modalRef = useRef()
  useEffect(() => {
    const handleOutsideClick = event => {
      // Close modal if click is outside of modalRef
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false)
      }
    }

    // Add event listener when component mounts
    document.addEventListener('mousedown', handleOutsideClick)

    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  const handleChange = () => {}
  return (
    <div
      className={`${
        showModal ? 'flex' : 'hidden'
      } w-full bg-black/20 h-screen fixed z-[10] top-0 left-0  items-center  justify-center`}
    >
      <div
        className='bg-white p-4 h-auto w-[400px] rounded-lg relative '
        ref={modalRef}
      >
        <div className='absolute top-[10px] right-[10px]'>
          <PiXLight
            size={30}
            className='cursor-pointer p-1 hover:bg-four/10 rounded-full'
            onClick={() => setShowModal(!showModal)}
          />
        </div>
        <h1 className='text-[30px]'>Generate Discount Code</h1>
        <div>
          <form className='flex flex-col gap-2 w-full '>
            <div className='w-full flex flex-col gap-[3px]'>
              <p className='required text-dark '>Discount Rate (percent)</p>
              <input
                type='number'
                name='discount_rate'
                placeholder='Discount Rate'
                onChange={e => handleChange(e)}
              />
            </div>

            <div className='w-full flex flex-col gap-[3px]'>
              <p className='required text-dark'>Expiry Date</p>
              <input
                type='date'
                name='expiry_date'
                placeholder='Expiry Date'
                onChange={e => handleChange(e)}
                rows={4}
              />
            </div>

            <div className='w-full flex flex-col gap-[3px]'>
              <p className='required text-dark'>Choose Product</p>
              <select name=''>
                <option value=''>For All</option>
                <option value=''>
                  Hadero Coffee Light Roast organic Arabica Ground Coffee ,
                  Arabica
                </option>
                <option>
                  Tomoca Coffee Medium Roast organic Arabica Blended Sidama
                  Coffee
                </option>
              </select>
            </div>

            <button
              type='button'
              className='p-2 e w-[150px]  hover:bg-secondary/90  px-4 py-2 bg-secondary text-white mt-[20px]'
            >
              Generate Code
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default GenerateSiscountCodeModal
