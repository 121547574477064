import React, { useState, useEffect, useRef } from 'react'
import { BiSolidCoffeeBean, BiSolidStore } from 'react-icons/bi'
import { MdOutlineAttachMoney } from 'react-icons/md'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import BarChart from '../../Components/Admin/BarChart'
import LineChart from '../../Components/Admin/LineChart'
import { motion, AnimatePresence } from 'framer-motion'
const Overview = () => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  // Date Picker
  const [dateSelect, setDateSelect] = useState(false)
  const selectDateRef = useRef(null)
  const selectDateButtonRef = useRef(null)

  const toggleMenu = () => {
    setDateSelect(prevIsMenuOpen => !prevIsMenuOpen)
  }
  useEffect(() => {
    const handleOutsideClick = event => {
      // Close modal if click is outside of modalRef
      if (
        selectDateRef.current &&
        !selectDateRef.current.contains(event.target) &&
        !selectDateButtonRef.current.contains(event.target)
      ) {
        setDateSelect(false)
      }
    }

    // Add event listener when component mounts
    document.addEventListener('mousedown', handleOutsideClick)

    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <div className='h-full w-full flex flex-col px-2 md:px-4 py-5 bg-white'>
      <div className='w-full flex flex-col md:flex-row items-start gap-5 justify-between'>
        <div>
          <h1 className='text-[30px] font-bold'>Hi, Admin</h1>
          <p className='font-light text-dark-three text-sm '>
            Today's date: {new Date().toDateString()}
          </p>
        </div>
        {/* Date Select */}
        <div className='flex flex-col gap-3 relative '>
          <button
            ref={selectDateButtonRef}
            onClick={toggleMenu}
            className='p-2 e w-[150px] rounded-md hover:bg-secondary/90  px-4 py-2 outline-none  bg-secondary text-primary border-r-4 border-secondary/80 cursor-pointer  '
          >
            Select Date
          </button>
          {dateSelect && (
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                ref={selectDateRef}
                className={`${
                  dateSelect ? 'flex' : ''
                } absolute bg-white top-full w-auto shadow-xl flex-col md:flex-row  px-2 py-3 rounded-lg gap-5  md:right-0 `}
              >
                <div className='flex flex-col'>
                  <p>Start Date:</p>
                  <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    inline
                    className='px-4 py-1 cursor-pointer font-bold text-[18px] bg-dark/10'
                  />
                </div>
                <div className='flex flex-col'>
                  <p>End Date: </p>
                  <DatePicker
                    inline
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    className='px-4 py-1 cursor-pointer font-bold text-[18px] bg-dark/10'
                  />
                </div>
              </motion.div>
            </AnimatePresence>
          )}
        </div>
      </div>

      <div className=' grid grid-cols-3 gap-4 auto-rows-mi w-full mt-5'>
        {/*  Sales Summary */}
        <div className='p-[15px] bg-secondary/15  flex flex-col  gap-5 overflow-hidden rounded-lg col-span-3 md:col-span-1 '>
          <h1 className='text-[25px] font-semibold flex items-center h-max gap-3 smm:text-[15px] sm:text-[18px]  '>
            <MdOutlineAttachMoney size={35} className='text-main-blue ' /> Total
            Sales
          </h1>
          <h1 className='text-[30px] smm:text-[18px] '>500,000 ETB / $4,500</h1>
          <p className='font-light text-dark-three text-sm smm:text-[15px] '>
            High Intensity:{' '}
            <span className='font-semibold smm:text-[15px] '>
              {' '}
              Northern America
            </span>
          </p>
        </div>
        {/*  order Summary */}
        <div className='p-[15px] bg-secondary/15  flex flex-col  gap-5 overflow-hidden rounded-lg col-span-3 md:col-span-2 lg:col-span-1'>
          <h1 className='text-[25px] font-semibold flex items-center h-max gap-3 smm:text-[15px] sm:text-[18px]  '>
            <BiSolidCoffeeBean size={35} className='text-main-blue ' /> Total
            Orders
          </h1>
          <h1 className='text-[30px] smm:text-[18px] '>500</h1>
          <p className='font-light text-dark-three text-sm smm:text-[15px] '>
            High Intensity:{' '}
            <span className='font-semibold smm:text-[15px] '>
              {' '}
              Northern America
            </span>
          </p>
        </div>

        {/*  Store Summary */}
        <div className='p-[15px] bg-secondary/15  flex flex-col  gap-5 overflow-hidden rounded-lg  col-span-3 lg:col-span-1 '>
          <h1 className='text-[25px] font-semibold flex items-center h-max gap-3 smm:text-[15px] sm:text-[18px]  '>
            <BiSolidStore size={35} className='text-main-blue ' /> Store Price
          </h1>
          <h1 className='text-[30px] smm:text-[18px] '>2,000,000 ETB</h1>
          <p className='font-light text-dark-three text-sm smm:text-[15px] '>
            Total Brands
            <span className='font-semibold smm:text-[15px] '> 20</span>
          </p>
        </div>

        {/* Bar chart order */}
        <div className='p-[15px] bg-three/10 col-span-3  gap-3 overflow-hidden rounded-lg '>
          <h1 className='text-[25px] font-semibold flex items-center h-max gap-2 sm:text-[18px]'>
            Order
          </h1>
          <LineChart
            labels={[
              'Monday',
              'Tuseday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
              'Sunday'
            ]}
            label='Order in the preveios days'
            data={[15, 110, 150, 50, 60, 140, 80]}
          />
        </div>

        <div className='items-center flex w-full flex-col gap-5 col-span-3 md:gap-5  md:flex-row'>
          {/* Top selling brand */}
          <div className='p-[15px] bg-three/10  gap-3 overflow-hidden rounded-lg w-full h-auto  md:w-1/2'>
            <h1 className='text-[25px] font-semibold flex items-center h-max gap-2 sm:text-[18px]'>
              Coffee Origins Alytics
            </h1>
            <BarChart
              label='Order'
              labels={[
                'yirga cheffe',
                'Jimma',
                'Sidama',
                'Guji',
                'Bale',
                'Wollega',
                'Harrar'
              ]}
              data={[15, 110, 150, 50, 60, 140, 80]}
            />
          </div>
          {/* Top selling brand */}
          <div className='p-[15px] bg-three/10  gap-3 overflow-hidden rounded-lg w-full h-auto  md:w-1/2 '>
            <h1 className='text-[25px] font-semibold flex items-center h-max gap-2 sm:text-[18px]'>
              Orders
            </h1>
            <BarChart
              labels={[
                'yirga cheffe',
                'Jimma',
                'Sidama',
                'Guji',
                'Bale',
                'Wollega',
                'Harrar'
              ]}
              label="'Coffee Origin Alytics'"
              data={[15, 110, 150, 50, 60, 140, 80]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview
