import './App.css'
import React, { useEffect } from 'react'
import axios from 'axios'
import { Route, Routes } from 'react-router-dom'
import ScrollToTop from './Components/ScrollToTop'
import Login from './Container/Login'
import Register from './Container/Register'
import Layout from './Container/Layout'
import Home from './Container/Home'
import Brands from './Container/Brands'
import Shop from './Container/Shop'
import Cart from './Container/Cart'
import NotFound from './Container/NotFound'
import BrandDetail from './Container/BrandDetail'
import ProductDetail from './Container/ProductDetail'
import ForgetPassword from './Container/ForgetPassword'
import PlaceOrder from './Container/PlaceOrder'
import useGlobal from './Core/global'
import SearchResults from './Container/SearchResults'
import OverView from './Container/Admin/Overview'
import AdminLayout from './Container/Admin/AdminLayout'
import Orders from './Container/Admin/Orders'
import Store from './Container/Admin/Store'
import Discount from './Container/Admin/Discount'
import User from './Container/Admin/User'
import useGeolocation from './hooks/useGeoLocation'
import OrderHistory from './Container/Account/OrderHistory'
import Pricing from './Container/Admin/Pricing'
import MyAccount from './Container/MyAccount'
import Toast from './Components/Toast'
import AccountDetail from './Container/Account/AccountDetail'
import About from './Container/About'

function App () {
  const { admin, setCountry, setCurrency, cartItems, authenticated } =
    useGlobal()
  const location = useGeolocation()
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const res = await axios.get('https://ipapi.co/json/')
        let country_code = res.data.country_code
        await setCountry(country_code)

        if (country_code === 'ET') {
          setCurrency('ETB')
        } else {
          setCurrency('USD')
        }
      } catch (error) {
        console.error('Error fetching country:', error)
      }
    }
    fetchCountry()
  }, [location])
  return (
    <div>
      <ScrollToTop />
      <Toast />
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/brands' element={<Brands />} />
          <Route path='/brands/:brand_id' element={<BrandDetail />} />
          <Route path='/shop' element={<Shop />} />
          <Route path='/shop/:product_id' element={<ProductDetail />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/about-us' element={<About />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/reset-password' element={<ForgetPassword />} />
          <Route path='/*' element={<NotFound />} />
          {authenticated && (
            <>
              <Route path='/my-account' element={<MyAccount />}>
                <Route index element={<AccountDetail />} />
                <Route path='orders' element={<OrderHistory />} />
              </Route>
            </>
          )}

          {cartItems.length > 0 && (
            <Route path='/place-order' element={<PlaceOrder />} />
          )}

          <Route path='/search/:searched' element={<SearchResults />} />
          {admin && (
            <>
              <Route path='admin' element={<AdminLayout />}>
                <Route index element={<OverView />} />
                <Route path='store'>
                  <Route index element={<Store />} />
                  <Route path='pricing/:item_Id' element={<Pricing />} />
                </Route>
                <Route path='orders' element={<Orders />} />
                <Route path='discount' element={<Discount />} />
                <Route path='users' element={<User />} />
              </Route>
            </>
          )}
        </Route>
        <Route path='/*' element={<NotFound />} />
      </Routes>
    </div>
  )
}

export default App
