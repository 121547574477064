import React from 'react'
import {
  FaFacebookF,
  FaYoutube,
  FaLinkedinIn,
  FaInstagram,
  FaRegCopyright
} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import useGlobal from '../Core/global'
const Footer = () => {
  const authenticated = useGlobal(state => state.authenticated)
  return (
    <div className='w-full bg-secondary footer min-h-auto'>
      <div className='m-auto h-full max-w-[1440px] p-[20px] gap-10 md:p-[30px] lg:p-[50px] flex flex-col items start justify-between md:flex-row '>
        <div className='flex flex-col gap-7'>
          <h1 className=''>abol</h1>

          <div className='flex flex-col gap-3'>
            <p>Tel: +2519000000</p>
            <p>Office: Bole</p>
            <p>abolcoffee@gmail.com</p>
          </div>
          <h2 className='font-extralight'>
            Organic coffee from the land of origins
          </h2>
        </div>
        <div className='flex w-2/3 gap-10 justify-between flex-col items-start md:flex-row'>
          <div>
            <h2 className=''> About</h2>
            <ul className='flex flex-col items-start gap-4  mt-5'>
              <li>Abol History</li>
              <li>Brands</li>
              <li>Delivery Information</li>
              <li>Payment Methods</li>
              <li>Terms and Condtions</li>
            </ul>
          </div>
          <div>
            <h2 className=''>Customer Service</h2>
            <ul className='flex flex-col items-start gap-4  mt-5'>
              <li>Contact Us</li>
              <li>Shipping and Delivery</li>
              <li>Issues</li>
            </ul>
          </div>
          <div>
            <h2 className=''> Account</h2>
            <ul className='flex flex-col items-start gap-4   mt-5'>
              {authenticated ? (
                <>
                  <li>
                    <Link className='text-primary' to='/my-account'>
                      My Account
                    </Link>
                  </li>
                  <li>
                    <Link className='text-primary' to='/my-account/orders'>
                      Orders
                    </Link>{' '}
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link className='text-primary' to={'/register'}>
                      Create Account
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
      <ul className='flex  gap-4 w-ful items-center justify-center'>
        <li className='p-2 '>
          <FaFacebookF size={20} />
        </li>
        <li className='p-2 '>
          <FaInstagram size={20} />
        </li>
        <li className='p-2 l'>
          <FaLinkedinIn size={20} />
        </li>
        <li className='p-2 '>
          <FaYoutube size={20} />
        </li>
      </ul>
      <div className='w-full bg-secondary'>
        <div className='  m-auto h-full py-[10px] max-w-[1440px] px-[20px] gap-5 md:px-[30px] lg:px-[50px] flex justify-center'>
          <p className='text-primary'>ABOL COFFEE</p>
          <p className='flex items-center gap-2 text-primary/70'>
            <span className='text-[14px]'>
              {' '}
              <FaRegCopyright />
            </span>{' '}
            All rights reserved! 2024{' '}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
