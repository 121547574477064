import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Checkbox } from '@material-tailwind/react'
import { PiXLight } from 'react-icons/pi'
import useGlobal from '../../Core/global'
import { data } from '../../Constants'
import SimpleMDE from 'react-simplemde-editor'

const UpdateStoreItemModel = ({ item, onClose }) => {
  const packageSizes = ['250g', '500g', '750g', '1kg']
  const processedTypes = ['Grounded', 'Not Grounded']
  const { modalOpen, closeModal } = useGlobal()
  const handleClose = () => {
    closeModal(item.id)
    onClose()
  }
  const modalRef = useRef()
  useEffect(() => {
    const handleOutsideClick = event => {
      // Close modal if click is outside of modalRef
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal(item.id)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const [ItemUpdate, setItemUpdate] = useState(item)

  // Form validation and Submiting

  const [formData, setFormData] = useState({
    product_name: '',
    brand_name: item.brand_name,
    roast_level: '',
    availible_stock: '',
    package_size: {
      '250g': false,
      '500g': false,
      '750g': false,
      '1kg': false
    },
    processed_type: {
      Grounded: false,
      'Not Grounded': false
    },
    description: '',
    procedure: '',
    storage: '',
    image: null
  })
  const [errors, setErrors] = useState({})
  const [ItemDetailUpdate, setItemDetailUpdate] = useState('')

  // Hnadle Change
  const onItemDetailChange = useCallback(value => {
    setItemDetailUpdate(value)
  }, [])
  const handleChange = e => {
    const { name, value, type, checked, files, dataset } = e.target
    const category = dataset.category
    if (type === 'checkbox') {
      console.log(category)
      setFormData({
        ...formData,
        [category]: {
          ...formData[category],
          [name]: checked
        }
      })
    } else if (type === 'file') {
      setFormData({
        ...formData,
        image: files[0]
      })
    } else {
      setFormData({
        ...formData,
        [name]: value
      })
    }
  }

  const validate = () => {
    const newErrors = {}

    if (!formData.product_name) {
      newErrors.product_name = 'Product Name is required'
    }

    if (!formData.brand_name) {
      newErrors.brand_name = 'Brand name is required'
    }

    if (!formData.roast_level) {
      newErrors.roast_level = ' Roast Level is required'
    }

    if (!formData.availible_stock) {
      newErrors.availible_stock = ' Availible Stock is required'
    }

    const PackageSizeCount = !Object.values(formData.package_size).some(
      checked => checked
    )
    if (PackageSizeCount) {
      newErrors.package_size = 'Package Size is required'
    }

    const ProcessedTypeCount = !Object.values(formData.processed_type).some(
      checked => checked
    )
    if (ProcessedTypeCount) {
      newErrors.processed_type = 'Processed Type is required'
    }

    if (!formData.file) {
      newErrors.file = 'File upload is required'
    }

    setErrors(newErrors)

    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = e => {
    e.preventDefault()

    console.log(formData)
    if (validate()) {
      // Proceed with form submission
      console.log('Form data:', formData)
    } else {
      console.log('Validation errors:', errors)
    }
  }
  return (
    <div
      className={`${
        modalOpen[item.id] ? 'flex' : 'hidden'
      } w-full bg-black/20 h-screen fixed z-[10] top-0 left-0  cursor-default items-center  justify-center`}
    >
      <div
        className='bg-white p-4 h-auto w-[400px] md:w-[600px] lg:w-[800px] rounded-lg relative '
        ref={modalRef}
      >
        <div className='absolute top-[10px] right-[10px]'>
          <PiXLight
            size={30}
            className='cursor-pointer p-1 hover:bg-secondary/10 rounded-full'
            onClick={handleClose}
          />
        </div>
        <h1 className='text-[30px] '>Update Item</h1>
        <div>
          <form
            className='modal flex flex-col gap-2 w-full max-h-[80vh] overflow-y-scroll'
            spellCheck={false}
          >
            <div className='flex gap-2 flex-col md:flex-row items-center md:gap-10 justify-between'>
              <div className='w-full  flex flex-col gap-[3px]'>
                <p className='required text-dark '> Product Name</p>
                <input
                  type='text'
                  name='product_name'
                  placeholder='Enter product name'
                  onChange={e => handleChange(e)}
                  defaultValue={item.product_name}
                />
              </div>
              <div className='w-full flex flex-col gap-[3px]'>
                <p className='required text-dark '> Brand Name</p>
                <select name='brand_name' value={formData.brand_name}>
                  <option value=''>Brand Name</option>
                  {data.Brands.map((brand, key) => (
                    <option key={key} value={brand.brand_name}>
                      {brand.brand_name}
                    </option>
                  ))}
                </select>
                {errors.brand_name && (
                  <p className='text-red-500 mt-2'>{errors.brand_name}</p>
                )}
              </div>
            </div>

            <div className='flex gap-2 flex-col md:flex-row items-center md:gap-10 justify-between'>
              <div className='w-full flex flex-col gap-[3px]'>
                <p className='required text-dark'> Roast Level</p>
                <select name=''>
                  <option value=''>Roast Level</option>
                </select>
              </div>
              <div className='w-full flex flex-col gap-[3px]'>
                <p className='required text-dark text-[14px]'>
                  Availible Stock
                </p>
                <input
                  type='number'
                  name='availible_stock'
                  placeholder='Enter availible stsock'
                  onChange={e => handleChange(e)}
                />
              </div>
            </div>

            <div className='w-full flex flex-col gap-[3px]'>
              <p className='required text-dark '>Package Size</p>
              <div className='flex flex-wrap items-center gap-5 justify-start'>
                {packageSizes.map((size, key) => (
                  <Checkbox
                    key={key}
                    type='checkbox'
                    value={size}
                    label={
                      <p
                        color='blue-gray'
                        className=' text-secondary font-serif flex font-medium'
                      >
                        {size}
                      </p>
                    }
                    ripple={false}
                    className='border-secondary rounded-none'
                  />
                ))}
              </div>
            </div>
            <div className='w-full flex flex-col gap-[3px]'>
              <p className='required text-dark'> Processed Types</p>
              <div className='flex items-center gap-5 justify-start'>
                {processedTypes.map((type, key) => (
                  <Checkbox
                    key={key}
                    type='checkbox'
                    value={type}
                    label={
                      <p
                        color='blue-gray'
                        className=' text-secondary font-serif flex font-medium'
                      >
                        {type}
                      </p>
                    }
                    ripple={false}
                    className='border-secondary rounded-none'
                  />
                ))}
              </div>
            </div>

            <div className='w-full flex flex-col gap-[3px]'>
              <p className='required text-dark '>Description</p>
              <SimpleMDE onChange={onItemDetailChange} />
            </div>

            <div className='w-full flex flex-col gap-[3px] '>
              <p className='text-dark required'>Product Image</p>
              <input
                type='file'
                id='file-button'
                name='image'
                onChange={e => handleChange(e)}
              />
            </div>

            <button
              type='button'
              className='p-2 e w-[150px]  hover:bg-secondary/90  px-4 py-2 bg-secondary text-white mt-[20px]'
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default UpdateStoreItemModel
