import React, { useState } from 'react'
import { PiXLight } from 'react-icons/pi'
import utils from '../Core/utils'
import useGlobal from '../Core/global'
import { PiPlus, PiMinus, PiTrashLight } from 'react-icons/pi'
const CartItem = ({ item }) => {
  const country = useGlobal(state => state.country)
  const [itemQuantity, setItemQuantity] = useState(item.quantity)
  const updateCartItemQuantity = useGlobal(
    state => state.updateCartItemQuantity
  )

  const removeItemFromCart = useGlobal(state => state.removeItemFromCart)
  const handleMinusQuantity = () => {
    setItemQuantity(itemQuantity - 1 < 1 ? 1 : itemQuantity - 1)
    updateCartItemQuantity(item.id, itemQuantity - 1 < 1 ? 1 : itemQuantity - 1)
  }
  const handlePlusQuantity = () => {
    if (itemQuantity >= item?.available_stock) {
      utils.triggerToast('This much stock is not availible!')
    } else {
      setItemQuantity(itemQuantity + 1)
      updateCartItemQuantity(item.id, itemQuantity + 1)
    }
  }

  return (
    <tr className='block md:table-row'>
      <td className='  items-center gap-5 flex '>
        <div className='h-[200px] overflow-hidden w-[150px] md:w-[200px] '>
          <img
            src={item?.image}
            alt=''
            className='object-contain md:object-cover'
          />
        </div>
        <div className='flex flex-col items-start w-full md:w-[45%]'>
          <h2 className='capitalize text-[20px]'>{item?.product_name}</h2>
          <p>{item?.brand_name}</p>

          <div className='flex gap-5 items-center'>
            <p className=''>{item?.package_size}</p>
            <p className=''>{item?.processed_type}</p>
            <p className='font-bold'>
              {country === 'ET'
                ? item?.price_birr + ' ETB'
                : '$ ' + item.price_usd}{' '}
            </p>
          </div>
        </div>
      </td>

      <td className='block md:table-cell'>
        <div className='flex items-center gap-2'>
          <div className='flex items-center gap-0 h-[40px]  border-[1px] border-secondary/40  hover:border-secondary transotion-all duration-300 '>
            <button
              onClick={() => handleMinusQuantity()}
              className=' px-3 py-1  font-bold hover:bg-four/90 h-full'
            >
              <PiMinus />
            </button>
            <input
              type='number'
              className='w-[50px] h-full rounded-none px-2 font-sans border-none outline-none bg-transparent'
              value={itemQuantity}
              onChange={e => {
                if (Number(e.target.value) >= item?.available_stock) {
                  utils.triggerToast('This much stock is not availible!', false)
                } else {
                  updateCartItemQuantity(
                    item.id,
                    Number(e.target.value) < 1 ? 1 : Number(e.target.value)
                  )
                  setItemQuantity(
                    Number(e.target.value) < 1 ? 1 : Number(e.target.value)
                  )
                }
              }}
            />
            <button
              onClick={() => handlePlusQuantity()}
              className='bg- px-3 py-1  font-bold hover:bg-four/90 h-full'
            >
              <PiPlus />
            </button>
          </div>
          <div className='  top-[10px] right-[10px]'>
            <PiTrashLight
              size={30}
              className='cursor-pointer p-1 hover:bg-accent/20 rounded-full'
              onClick={() => removeItemFromCart(item.id)}
            />
          </div>
        </div>
      </td>
      <td className='md:table-cell'>
        <div>
          <p className=' font-bold text-[20px] '>
            {country === 'ET'
              ? item?.price_birr * itemQuantity + ' ETB'
              : '$ ' + item?.price_usd * itemQuantity}
          </p>
        </div>
      </td>
    </tr>
  )
}

export default CartItem
