import React, { useState, useRef } from 'react'
import { PiEyeLight, PiEyeSlashLight } from 'react-icons/pi'
const InputField = ({
  ispass,
  type,
  value,
  onChange,
  name,
  error,
  placeholder,
  label,
  required
}) => {
  const [pass, setPass] = useState(ispass)
  const inputRef = useRef(null)
  const inputContainerRef = useRef(null)

  const toggleInputType = () => {
    if (inputRef.current) {
      if (inputRef.current.type === 'password') {
        inputRef.current.type = 'text'
        setPass(false)
      } else {
        inputRef.current.type = 'password'
        setPass(true)
      }
    }

    console.log(inputRef.current.type)
  }

  const handleFocus = () => {
    inputContainerRef.current.style.borderColor = '#3a2618'
  }

  const handleBlur = () => {
    inputContainerRef.current.style.borderColor = 'rgba(58, 38, 24, 0.2)'
  }
  return (
    <div className='input-container w-full'>
      {label && <label className={`${required && 'required'}`}>{label}</label>}
      <div
        className={`${
          error && 'border-red-600 placeholder:text-red-600'
        } input-div hover:border-secondary`}
        ref={inputContainerRef}
      >
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value && value}
          label='Password'
          onChange={e => onChange(e)}
          ref={inputRef}
          onFocus={() => handleFocus()}
          onBlur={handleBlur}
        />
        {ispass && (
          <button type='button' onClick={() => toggleInputType()}>
            {pass ? (
              <PiEyeLight color='black' size={25} />
            ) : (
              <PiEyeSlashLight color='black' size={25} />
            )}
          </button>
        )}
      </div>

      {error && <p className='text-red-400 '>{error}</p>}
    </div>
  )
}

export default InputField
