import React from 'react'
import { Bar } from 'react-chartjs-2'
import { registerables, Chart } from 'chart.js'
Chart.register(...registerables)
const BarChart = ({ labels, data, label, title }) => {
  const chartData = {
    labels: ['Hadero', 'Enabe', 'Toye', 'Cubico', 'Wild'],
    datasets: [
      {
        label: label,
        data: data,
        backgroundColor: ['#6f3f1083']
      }
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: title
      }
    },
    scales: {
      x: {
        type: 'category',
        labels: labels,
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        grid: {
          display: false,
          drawBorder: true
        }
      }
    }
  }
  return (
    <div style={{ height: 400, width: '100%' }}>
      <Bar data={chartData} options={options} />
    </div>
  )
}

export default BarChart
