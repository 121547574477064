import React from 'react'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { Button } from '@material-tailwind/react'
const Brand = ({ brand, rep }) => {
  const { id, image, brand_name, origin } = brand
  const navigate = useNavigate()
  return (
    <div
      className={`${
        rep % 2 === 1 ? '  md:flex-row-reverse ' : ' md:flex-row'
      } ' flex justify-between w-full overflow-hidden  transition-all flex-col-reverse  duration-500 hover:ease-in  items-center gap-5 md:gap-10 '`}
    >
      <div className='rounded-none w-full md:w-1/2'>
        <img
          src={brand.brand_image}
          alt=''
          className='mix-blend-darken rounded-none'
        />
      </div>
      <div className='p-3 flex flex-col gap-3 w-fill md:w-1/2'>
        <motion.h1
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.2 }}
          className='font-bold'
        >
          {brand_name}
        </motion.h1>
        <motion.p
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.3 }}
        >
          Ethiopia {origin}
        </motion.p>
        <motion.p
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veritatis
          unde magnam aut illum, sit voluptate dolorem minus odio quibusdam
          temporibus fugit ipsam mollitia ipsa et, libero fugiat vitae, vero ad.
        </motion.p>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.8 }}
          className='w-full flex justify-center md:justify-start'
        >
          <Button
            onClick={() => navigate(`/brands/${id}`)}
            variant='outlined'
            className='p-3 border-secondary border-[2px]  bg-transparent text-secondary font-serif text-[17px] capitalize w-[200px] hover:bg-secondary hover:text-primary rounded-none'
          >
            Learn More
          </Button>
        </motion.div>
      </div>
    </div>
  )
}

export default Brand
