import React, { useState } from 'react'
import { data } from '../../Constants'
import Pagination from '../../Components/Pagination'
const OrderHistory = () => {
  // Pagination
  const [currentPage, setCurrentPage] = useState(0)
  const productsPerPage = 2 // This chan be changed

  const pageCount = Math.ceil(data.UserOrder.length / productsPerPage)

  const handlePageChange = selectedPage => {
    setCurrentPage(selectedPage)
  }

  const orderHistoryData = data.UserOrder.slice(
    currentPage * productsPerPage,
    (currentPage + 1) * productsPerPage
  )
  return (
    <div className='h-auto w-full'>
      <div className='h-auto w-full  px-[20px] md:px-[30px] lg:px-[40px] flex-col items-start justify-between gap-10 '>
        <div className='space-y-4'>
          <h1 className='text-[40px]'>Previous Orders</h1>
          <p>
            Hello John Doe From your account details you can edit your password
            and account details.
          </p>
        </div>

        <div className='w-full overflow-x-scroll'>
          <table className='w-full mt-3  order-table' id='order-table'>
            <thead className=''>
              <tr>
                <td>No</td>
                <td>Item</td>
                <td>Date</td>
                <td>Quantity</td>
                <td>Package size</td>
                <td>Price</td>
                <td>Address</td>
                <td>Discount</td>
                <td>Delivery Instruction</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {orderHistoryData.map((order, key) => (
                <tr key={key}>
                  <td className='max-w-[20px]'>{key + 1}</td>

                  <td>{order.date}</td>
                  <td>{order.item}</td>
                  <td>{order.quantity}</td>
                  <td>{order.package_size}</td>
                  <td>{order.price}</td>
                  <td>{order.address}</td>
                  <td>{order.discount_code}</td>
                  <td>{order.delivery_instruction}</td>
                  <td className='w-[50px]'>
                    {order.deliverd ? (
                      <p className='text-green-800 border-[1px] border-green-500 bg-green-400/30 rounded-md p-1 md:p-2 text-center font-bold'>
                        Recieved
                      </p>
                    ) : (
                      <p className='text-red-800 border-[1px] border-red-400 bg-red-400/30 rounded-md text-[13px] md:text-[16px] p-1 md:p-2 text-center font-bold'>
                        Not Recieved
                      </p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
      </div>
    </div>
  )
}

export default OrderHistory
