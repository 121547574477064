import React, { useState, useRef, useEffect } from 'react'

import InputField from '../../Components/InputField'
import { Button } from '@material-tailwind/react'
const AccountDetail = () => {
  const firstNameEditRef = useRef()
  const emailEditRef = useRef()
  const lastNameEditRef = useRef()
  const [editFirstName, setEditFirstName] = useState(false)
  const [editLastName, setEditLastName] = useState(false)
  const [editEmail, setEditEmail] = useState(false)
  const [editPassword, setEditPassword] = useState(false)
  const [email, setEmail] = useState('John@gmail.com')
  const [firstName, setFirstName] = useState('John')
  const [lastName, setLastName] = useState('Doe')

  const [error, setError] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: ''
  })

  const handleFirstNameEdit = () => {
    setEditFirstName(!editFirstName)
    firstNameEditRef.current.focus()

    if (firstName === '' || firstName === null) {
      setError({
        ...error,
        first_name: 'First nme is required'
      })
      return
    }
  }

  const handleLastNameEdit = () => {
    setEditLastName(!editLastName)
    lastNameEditRef.current.focus()
    if (lastName === '' || lastName === null) {
      setError({
        ...error,
        last_name: 'last name is required'
      })
      return
    }
  }

  const handleEmaiEdit = () => {
    setEditEmail(!editEmail)
    emailEditRef.current.focus()

    if (email === '' || email === null) {
      setError({
        ...error,
        email: 'email is required'
      })
      return
    }
  }

  const handlePasswordEdit = () => {
    setEditPassword(!editPassword)
  }

  return (
    <div className='h-auto w-full flex items-center justify-center'>
      <div className='h-auto px-[20px] md:px-[30px] lg:px-[40px] flex-col items-start justify-between gap-4'>
        <div className='space-y-4'>
          <h1 className='text-[40px]'>Account Detail</h1>
          <p>
            Hello John Doe From your account details you can edit your password
            and account details.
          </p>
        </div>

        <div className='w-full  sm:w-full md:w-[650px]'>
          <form className='w-full flex flex-col items-start gap-4'>
            <div className='flex items-center flex-col gap-0  md:flex-row justify-between md:gap-10  w-full'>
              <InputField
                type='text'
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                name='first_name'
                label='First Name'
                error={error?.first_name && error.first_name}
              />

              <InputField
                label='Last Name'
                type='text'
                name='last_name'
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                error={error?.last_name && error.last_name}
              />
            </div>

            <InputField
              name='email'
              label='Email'
              type='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              error={error?.email && error.email}
            />

            <p className='mt-[25px]'>Leave Blank for Unchanged</p>
            <InputField
              name='password'
              label='Password'
              type='password'
              onChange={e => setEmail(e.target.value)}
              error={error?.email && error.email}
              ispass={true}
            />
            <InputField
              name='confirm_password'
              label='Confirm Password'
              type='password'
              onChange={e => setEmail(e.target.value)}
              error={error?.email && error.email}
              ispass={true}
            />

            <Button className='p-3 mt-[25px] bg-accent text-primary border-none font-serif text-[17px] capitalize w-[170px] hover:bg-olivegreen rounded-none'>
              Save Changes
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AccountDetail
