import React, { useState, useEffect, useRef } from 'react'
import Flickity from 'flickity'
import { PiCaretLeft, PiCaretRight } from 'react-icons/pi'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import useGlobal from '../Core/global'
const ProductsSlider = ({products}) => {
  const flickityRef = useRef(null)
  const flkty = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const country = useGlobal(state => state.country)
  var options = {
    initialIndex: 0,
    cellAlign: 'left',
    wrapAround: false,
    pageDots: true,
    imagesLoaded: true,
    prevNextButtons: false,
    selectedAttraction: 0.01,
    friction: 0.2,
    autoPlay: 3000,
    contain: true
  }

  if (matchMedia('screen and (min-width: 768px)').matches) {
    options.groupCells = true
    options.groupCells = 1
  }
  useEffect(() => {
    flkty.current = new Flickity(flickityRef.current, options)

    flkty.current.on('change', index => {
      setCurrentSlide(index)
    })

    return () => {
      flkty.current.destroy()
    }
  }, [])
  const handlePrev = () => {
    if (flkty.current) {
      flkty.current.previous()
    }
  }

  const handleNext = () => {
    if (flkty.current) {
      flkty.current.next()
    }
  }
  return (
    <motion.div
      className='slider-container relative w-full h-[470px]  '
      initial={{ y: 150, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 1.5, delay: 0.6 }}
    >
      <div className='flickity-slider' ref={flickityRef}>
        {products?.map((item, index) => (
          <Link
            to={`/shop/${item.id}`}
            key={index}
            className='carousel-cell shadow-md w-full md:w-[300px] rounded-sm overflow-hidden flex bg-secondary/5 hover:bg-secondary/10 hover:shadow-2xl transition-all duration-300 h-[400px] group flex-col items-start gap-5  mr-[40px] my-[50px] border-[1px] border-secondary/5'
          >
            <div className='h-[250px] w-full overflow-hidden transform transition-transform duration-500 ease-in-out group-hover:scale-110 '>
              <img
                src={item.image}
                className='object-cover transform  mix-blend-darken'
              />
            </div>

            <div className='p-4 flex flex-col gap-5'>
              <div>
                <h2 className='text-secondary text-[20px] font-bold'>
                  {item.product_name}
                </h2>

                <div className='flex items-center justify-between'>
                  <p className='sm:px-3 sm:text-[15px] w-max py-1 px-4 bg-secondary/10 rounded-2xl'>
                    {item.roast_level}
                  </p>

                  <h2 className='font-bold'>
                    {country === 'ET'
                      ? item.price_birr + ' ETB'
                      : '$' + item.price_usd}
                  </h2>
                </div>
              </div>
              {item.available_stock <= 10 && (
                <div className='bg-red-400 px-3 w-max rounded-md '>
                  <p className='text-primary'>Stock: #{item.available_stock}</p>
                </div>
              )}
            </div>
          </Link>
        ))}
      </div>
      <button
        onClick={handlePrev}
        className='absolute top-1/2 left-[10px] md:left-[40px] transform  -translate-y-1/2 flex items-center justify-center bg-secondary/10  hover:bg-secondary rounded-full   w-[60px] h-[60px]'
      >
        <PiCaretLeft size={35} color='white' />
      </button>
      <button
        onClick={handleNext}
        className='absolute top-1/2 right-[10px]   md:right-[40px] transform -translate-y-1/2 flex items-center justify-center bg-secondary/10 hover:bg-secondary rounded-full   w-[60px] h-[60px]'
      >
        <PiCaretRight size={35} color='white' />
      </button>
    </motion.div>
  )
}

export default ProductsSlider
